import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {uploadZainRevenueReport, uploadZainSubscriptionReport} from "../../services/reportsUploadService";
import * as XLSX from "xlsx";
import {FiCalendar, FiChevronRight, FiHome} from "react-icons/fi";
import DatePicker from "react-datepicker";
import cx from "classnames";
import FullModal from "../FullModal";
import {addZainFinancialReports} from "../../services/customSdpApiService";


function formatRevenueNumber(value) {
  const normalized = value.toString().replace(/,/g, '');

  const parsedNumber = parseFloat(normalized);

  return parsedNumber.toFixed(2);
}

function UploadFromFileModal(){

  const [reportType, setReportType] = useState(null);
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState([]);

  const uploadNewFile = ()=>{
    setFileData([]);
    setFile(null);
  }

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setFileData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if(file){
      handleFile(file);
    }
  }, [file]);

  const columnNames = fileData.length > 0 ? Object.keys(fileData[0]) : [];

  if(!file){
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div className="w-1/3 flex items-center justify-center gap-4 " >
          <div className="w-1/2" >
            <select
              onChange={(e)=> setReportType(e.target.value) }
              className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5">
              <option defaultValue >Choose a report type</option>
              <option value="REVENUE">Revenue report</option>
              <option value="SUBSCRIPTION">Subscription report</option>
            </select>
          </div>
          <div className="w-1/2" >
            <input
              disabled={!reportType || !["REVENUE", "SUBSCRIPTION"].includes(reportType)}
              className="block w-full text-sm text-slate-500
                          file:mr-4 file:py-1.5 file:px-2 file:rounded-md
                          file:border-0 file:text-sm file:font-semibold
                          file:bg-sky-50 file:text-sky-700 file:cursor-pointer
                          hover:file:bg-sky-100 border rounded-lg appearance-none"
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="overflow-y-auto h-[77vh] flex-wrap">
        <table className="min-w-full border-collapse">
          <thead className="bg-gray-100 customShadow">
          <tr>
            {columnNames.map((columnName, index) => (
              <th key={index} className="text-left py-3 px-2 uppercase font-semibold text-xs">
                {columnName}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
          {fileData.map((row, index) => (
            <tr key={index} className="border">
              {columnNames.map((col, idx) => (
                <td key={idx} className="text-left py-3 px-2">
                  {row[col]}
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between mt-4">
        <button onClick={() => uploadNewFile()}
                className="bg-white px-5 py-2 rounded-lg border text-gray-600 font-semibold">
          New file
        </button>
        <button className="bg-azure px-5 py-2 rounded-lg text-white font-semibold">
          Upload
        </button>
      </div>
    </div>
  )
}

function StcFinancial() {

  const {t} = useTranslation();
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const [file, setFile] = useState(null);
  const [date, setDate] = useState(null);
  const [fileData, setFileData] = useState([]);

  const openNewFileModal = () => {
    setIsNewModalOpen(true);
  }
  const closeNewFileModal = () => {
    setIsNewModalOpen(false);
  }

  const showFinishSwal = () => {
    withReactContent(Swal)
      .fire({
        title: "File uploaded with success",
        icon: "success",
        showConfirmButton: true,
      })
      .then(()=>{
        setDate(null);
        setFile(null);
        setFileData([]);
      })
  }

  const showLoadingSwal = () => {
    withReactContent(Swal)
      .fire({
        title: "Upload loading",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
  }

  const uploadRevenueFile = async () => {

    showLoadingSwal();

    const data = fileData.map((row) => {
      const parsedDate = date ? new Date(date) : null;
      return {
        Month: parsedDate ? parsedDate.getMonth() + 1 : null,
        Year: parsedDate ? parsedDate.getFullYear() : null,
        ServiceId: parseInt(row.serviceId, 10),
        ServiceName: row.serviceName,
        OpId: 4,
        Operator: "STC",
        Revenue: formatRevenueNumber(row.totalRevenue),
        SPShare: formatRevenueNumber(row.soShare),
      };
    });

    await addZainFinancialReports({data: data})
      .then((res)=>{
        Swal.close();
        showFinishSwal();
        console.log(res);
      })
      .catch((err)=>{
        Swal.close();
        console.log(err);
      });
  }

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: 'array', cellDates: true });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      // const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });


      const mappedData = jsonData.map((row) => {

        if (Object.keys(row).length < 10) {
          return null;
        }

        const normalizedRow = Object.fromEntries(
          Object.entries(row).map(([key, value]) => [key.trim(), value])
        );

        return {
          type: normalizedRow['Type'],
          month: normalizedRow['Month'],
          shortCode: normalizedRow['Sort code'],
          serviceId: normalizedRow['Service Id'],
          totalRevenue: normalizedRow['Total revenue'],
          stcShare: normalizedRow['STC_SHARE'],
          fitShare: normalizedRow['FIT_SHARE'],
          hits: normalizedRow['HITS'],
          serviceName: normalizedRow['SERVICE NAME'],
          channalName: normalizedRow['CHANNAL NAME'],
          soId: normalizedRow['SO ID'],
          soName: normalizedRow['SO NAME'],
          soPercentage: normalizedRow['SO%'],
          soShare: normalizedRow['SO Share'],
        };
      }).filter(row => row !== null);

      console.log(mappedData);

      setFileData(mappedData);
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (file) {
      handleFile(file);
    }
  }, [file]);

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      const updatedDate = new Date(selectedDate);
      updatedDate.setHours(13, 0, 0, 0);
      setDate(updatedDate);
      console.log(updatedDate);
    } else {
      setDate(null);
    }
  };

  const columnNames = fileData.length > 0 ? Object.keys(fileData[0]) : [];

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("OPERATOR_REPORTS.TITLE")}</h4>
        <span className="text-xs font-semibold flex items-center">
          <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("OPERATOR_REPORTS.STC_FINANCIAL.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("OPERATOR_REPORTS.STC_FINANCIAL.TITLE")}</h1>
        <div className="flex items-center gap-3">
          <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white">
            <FiCalendar className="text-gray-400"/>
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              type="text"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText='Date'
              isClearable
            />
          </div>
          <input
            className="block w-full text-sm text-slate-500
                          file:mr-4 file:py-1.5 file:px-2 file:rounded-md
                          file:border-0 file:text-sm file:font-semibold
                          file:bg-sky-50 file:text-sky-700 file:cursor-pointer
                          hover:file:bg-sky-100 border rounded-lg appearance-none"
            type="file"
            disabled={date === null}
            onChange={(e) => setFile(e.target.files[0])}
          />
          <button
            onClick={() => uploadRevenueFile()}
            disabled={file === null}
            className={cx("cursor-pointer whitespace-nowrap w-max px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow", {
              'bg-gray-400': file === null,
              'bg-azure': file !== null,
            })}
          >
            {t("OPERATOR_REPORTS.MOBILY.ADD_BTN")}
          </button>
        </div>
        {/*<button*/}
        {/*  onClick={() => openNewFileModal()}*/}
        {/*  className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">*/}
        {/*  {t("OPERATOR_REPORTS.ZAIN.ADD_BTN")}*/}
        {/*</button>*/}
      </div>

      <div className="overflow-y-auto h-[80vh] flex-wrap">
        <table className="min-w-full border-collapse">
          <thead className="bg-gray-100 customShadow sticky top-0">
          <tr>
            {columnNames.map((columnName, index) => (
              <th key={index} onClick={()=> console.log(columnNames) } className="text-left py-3 px-2 uppercase font-semibold text-xs">
                {columnName}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
          {fileData.map((row, index) => (
            <tr key={index} className="border">
              {columnNames.map((col, idx) => (
                <td key={idx} className="text-left py-3 px-2">
                  {row[col]}
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
      </div>

      <FullModal isOpen={isNewModalOpen} title="Upload data from file" close={closeNewFileModal}>
        <UploadFromFileModal close={closeNewFileModal}/>
      </FullModal>
    </div>
  );
}

export default StcFinancial;