import React, {useEffect, useState} from 'react';
import {FiCalendar, FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FullModal from "../FullModal";
import * as XLSX from 'xlsx';
import cx from "classnames";
import DatePicker from "react-datepicker";
import {
  uploadMobilyRevenueReport,
  uploadZainRevenueReport,
  uploadZainSubscriptionReport
} from "../../services/reportsUploadService";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment";

function Zain() {

  const {t} = useTranslation();

  const [file, setFile] = useState(null);
  const [date, setDate] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [reportType, setReportType] = useState(null);

  const selectReportType = (val) => {
    setReportType(val);
    setFile(null);
    setFileData([]);
  }

  const showFinishSwal = () => {
    withReactContent(Swal)
      .fire({
        title: "File uploaded with success",
        icon: "success",
        showConfirmButton: true,
      })
      .then(()=>{
        setReportType(null);
        setDate(null);
        setFile(null);
        setFileData([]);
      })
  }

  const showLoadingSwal = () => {
    withReactContent(Swal)
      .fire({
        title: "Upload loading",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
  }

  const uploadSubscriptionFile = async () => {

    showLoadingSwal();

    const data = {
      "operatorId": 3,
      "dated": date,
      "subLogs": fileData
    }
    await uploadZainSubscriptionReport(data)
      .then((res)=>{
        Swal.close();
        showFinishSwal();
        console.log(res);
      })
      .catch((err)=>{
        Swal.close();
        console.log(err);
      });
  }

  const uploadRevenueFile = async () => {

    showLoadingSwal();

    const data = {
      "operatorId": 3,
      "dated": date,
      "serviceLogs": fileData
    }
    await uploadZainRevenueReport(data)
      .then((res)=>{
        Swal.close();
        showFinishSwal();
        console.log(res);
      })
      .catch((err)=>{
        Swal.close();
        console.log(err);
      });
  }

  const uploadFile = async () => {
    switch (reportType) {
      case "SUBSCRIPTION":
        uploadSubscriptionFile();
        break;
      case "REVENUE":
        uploadRevenueFiles();
        break;
      default:
        break;
    }
  }

  const handleSubscriptionFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const mappedData = jsonData.map(row => ({
        serviceId: row['ID'],
        serviceName: row['Service Name'],
        type: row['Type'],
        partner: row['Partner'],
        subscriptionCount: row['Subscription Count'],
        free: row['Free'],
        newSubCount: row['New Subscription'],
        unsubCount: row['Unsubscription'],
      }));

      setFileData(mappedData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleRevenueFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const arrayBuffer = event.target.result;
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);

          // Map and format data
          const mappedData = jsonData.map((row) => {
            const formattedDate = moment(row['Time'].split('~')[0]).add(2, 'hours').toISOString();
            return {
              time: formattedDate,
              serviceId: row['Content/  ServiceID '],
              serviceName: row['Service Name'],
              productId: row['Product ID'],
              serviceType: row['Service Type'],
              subOperator: row['Sub Operator'],
              partnerName: row['Partner Name'],
              tariff: row['Tariff'],
              prepaidRevenue: row['Pre-Revenue'],
              postpaidRevenue: row['Pos-Revenue'],
              totalRevenue: row['Total Revenue'],
              transactionCount: row['Count of Transaction '],
            };
          });

          resolve(mappedData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadRevenueFiles = async () => {
    if (file.length === 0) {
      Swal.fire('No files selected', 'Please select files to upload.', 'warning');
      return;
    }

    showLoadingSwal();

    try {
      for (const singleFile of file) {
        // Process each file
        const mappedData = await handleRevenueFile(singleFile);

        // Get the date from the first entry (assuming all rows have the same date)
        const fileDate = mappedData[0]?.time || null;

        if (!fileDate) {
          throw new Error('Invalid file data: Missing date.');
        }

        // Upload the processed data
        const data = {
          operatorId: 3, // Static operatorId
          dated: fileDate, // Extracted and formatted date
          serviceLogs: mappedData,
        };

        console.log(data);

        await uploadZainRevenueReport(data);
      }

      Swal.close();
      showFinishSwal(); // Show success message when all files are uploaded
    } catch (error) {
      Swal.close();
      Swal.fire('Error', `File upload failed: ${error.message}`, 'error');
      console.error('Error during file upload:', error);
    }
  };

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      const updatedDate = new Date(selectedDate);
      updatedDate.setHours(13, 0, 0, 0);
      setDate(updatedDate);
      console.log(updatedDate);
    } else {
      setDate(null);
    }
  };

  const columnNames = fileData.length > 0 ? Object.keys(fileData[0]) : [];

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("OPERATOR_REPORTS.TITLE")}</h4>
        <span className="text-xs font-semibold flex items-center">
          <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("OPERATOR_REPORTS.ZAIN.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("OPERATOR_REPORTS.ZAIN.TITLE")}</h1>
        <div className="flex items-center gap-3">
          <div>
            <select
              onChange={(e) => selectReportType(e.target.value)}
              className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg whitespace-nowrap w-max px-2 py-1.5">
              <option defaultValue>Choose a report type</option>
              <option value="REVENUE">Revenue report</option>
              <option value="SUBSCRIPTION">Subscription report</option>
            </select>
          </div>
          <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white">
            <FiCalendar className="text-gray-400"/>
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              type="text"
              dateFormat="dd/MM/yyyy"
              placeholderText='Date'
              isClearable
              disabled={!reportType || !["REVENUE", "SUBSCRIPTION"].includes(reportType)}
            />
          </div>
          <input
            className="block w-full text-sm text-slate-500
                        file:mr-4 file:py-1.5 file:px-2 file:rounded-md
                        file:border-0 file:text-sm file:font-semibold
                        file:bg-sky-50 file:text-sky-700 file:cursor-pointer
                        hover:file:bg-sky-100 border rounded-lg appearance-none"
            type="file"
            multiple
            // disabled={date === null}
            onChange={(e) => setFile(Array.from(e.target.files))}
          />
          <button
            onClick={() => uploadFile()}
            disabled={file === null}
            className={cx("cursor-pointer whitespace-nowrap w-max px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow", {
              'bg-gray-400': file === null,
              'bg-azure': file !== null,
            })}
          >
            {t("OPERATOR_REPORTS.MOBILY.ADD_BTN")}
          </button>
        </div>
        {/*<button*/}
        {/*  onClick={() => openNewFileModal()}*/}
        {/*  className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">*/}
        {/*  {t("OPERATOR_REPORTS.ZAIN.ADD_BTN")}*/}
        {/*</button>*/}
      </div>

      <div>
        <p>Selected Files:</p>
        <ul>
          { file &&
            file.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))
          }
        </ul>
      </div>

      {/*<div className="overflow-y-auto h-[80vh] flex-wrap">*/}
      {/*  <table className="min-w-full border-collapse">*/}
      {/*    <thead className="bg-gray-100 customShadow">*/}
      {/*    <tr>*/}
      {/*      {columnNames.map((columnName, index) => (*/}
      {/*        <th key={index} onClick={()=> console.log(columnNames) } className="text-left py-3 px-2 uppercase font-semibold text-xs">*/}
      {/*          {columnName}*/}
      {/*        </th>*/}
      {/*      ))}*/}
      {/*    </tr>*/}
      {/*    </thead>*/}
      {/*    <tbody className="text-gray-800 text-sm">*/}
      {/*    {fileData.map((row, index) => (*/}
      {/*      <tr key={index} className="border">*/}
      {/*        {columnNames.map((col, idx) => (*/}
      {/*          <td key={idx} className="text-left py-3 px-2">*/}
      {/*            {row[col]}*/}
      {/*          </td>*/}
      {/*        ))}*/}
      {/*      </tr>*/}
      {/*    ))}*/}
      {/*    </tbody>*/}
      {/*  </table>*/}
      {/*</div>*/}

    </div>
  );
}

export default Zain;
