import axios from "axios";

export const createApi = async () => {
  try {
    const configResponse = await fetch('/config.json');
    const config = await configResponse.json();

    const api = axios.create({ baseURL: `${config.baseApiUrl}/localapi/api` });
    return api;
  } catch (error) {
    console.error('Error fetching API configuration:', error);
    return axios.create();
  }
};

export const customApi = await createApi();


export const getOmanCampaignStatistics = async (operatorId, year)=>{
  try {
    const response = await customApi.post('/oman-shares',{
      "OperatorId": operatorId,
      "year": year
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const addZainFinancialReports = async (data)=>{
  try {
    const response = await customApi.post('/add-zain-financial',data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getFinancialReports = async (data)=>{
  try {
    const response = await customApi.post('/get-financial-reports',data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getFinancialStatistics = async (data)=>{
  try {
    const response = await customApi.post('/get-financial-statistics',data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getFinancialDetails = async (data)=>{
  try {
    const response = await customApi.post('/get-financial-details',data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}