import React, {useEffect, useState} from 'react';
import {
  FiBarChart,
  FiBarChart2,
  FiBriefcase,
  FiChevronRight,
  FiFile,
  FiHome,
  FiPieChart,
  FiUserPlus
} from "react-icons/fi";
import {useTranslation} from "react-i18next";

import cx from "classnames";
import axios from "axios";
import {getFinancialDetails} from "../services/customSdpApiService";

const dataMobily = [
  {
    id: 1,
    serviceName: 'YourSpot',
    January: { afterOp: 18402.00, riseFall: 0 },
    February: { afterOp: 14887.20, riseFall: -19 },
    March: { afterOp: 14835.60, riseFall: 0 },
    April: { afterOp: 12802.80, riseFall: -14 },
    May: { afterOp: 12100.00, riseFall: -5 },
    June: { afterOp: 10609.20, riseFall: -12 },
    July: { afterOp: 10183.60, riseFall: -4 },
    August: { afterOp: 9342.00, riseFall: -8 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 2,
    serviceName: 'Hiya',
    January: { afterOp: 7994.98, riseFall: 0 },
    February: { afterOp: 7035.86, riseFall: -12 },
    March: { afterOp: 7010.26, riseFall: 0 },
    April: { afterOp: 6501.00, riseFall: -7 },
    May: { afterOp: 6404.90, riseFall: -1 },
    June: { afterOp: 5700.82, riseFall: -11 },
    July: { afterOp: 5645.64, riseFall: -1 },
    August: { afterOp: 5276.04, riseFall: -7 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 3,
    serviceName: 'Game Zone',
    January: { afterOp: 3458.93, riseFall: 0 },
    February: { afterOp: 2997.19, riseFall: -13 },
    March: { afterOp: 3091.97, riseFall: 3 },
    April: { afterOp: 2811.86, riseFall: -9 },
    May: { afterOp: 2801.57, riseFall: 0 },
    June: { afterOp: 2485.82, riseFall: -11 },
    July: { afterOp: 2419.82, riseFall: -3 },
    August: { afterOp: 2298.12, riseFall: -5 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 4,
    serviceName: 'Level Up',
    January: { afterOp: 1372.00, riseFall: 0 },
    February: { afterOp: 1182.00, riseFall: -14 },
    March: { afterOp: 1205.60, riseFall: 2 },
    April: { afterOp: 1164.80, riseFall: -3 },
    May: { afterOp: 1100.00, riseFall: -6 },
    June: { afterOp: 1016.00, riseFall: -8 },
    July: { afterOp: 1061.20, riseFall: 4 },
    August: { afterOp: 1017.60, riseFall: -4 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 5,
    serviceName: 'FunZone',
    January: { afterOp: 739.60, riseFall: 0 },
    February: { afterOp: 661.60, riseFall: -11 },
    March: { afterOp: 710.80, riseFall: 7 },
    April: { afterOp: 623.20, riseFall: -12 },
    May: { afterOp: 566.80, riseFall: -9 },
    June: { afterOp: 503.20, riseFall: -11 },
    July: { afterOp: 491.20, riseFall: -2 },
    August: { afterOp: 455.20, riseFall: -7 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 6,
    serviceName: 'Mysterious tricks',
    January: { afterOp: 822.40, riseFall: 0 },
    February: { afterOp: 712.40, riseFall: -13 },
    March: { afterOp: 789.60, riseFall: 11 },
    April: { afterOp: 645.20, riseFall: -18 },
    May: { afterOp: 657.60, riseFall: 2 },
    June: { afterOp: 541.60, riseFall: -18 },
    July: { afterOp: 524.80, riseFall: -3 },
    August: { afterOp: 519.20, riseFall: -1 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 7,
    serviceName: 'Sports Zone',
    January: { afterOp: 1068.80, riseFall: 0 },
    February: { afterOp: 908.00, riseFall: -15 },
    March: { afterOp: 888.40, riseFall: -2 },
    April: { afterOp: 827.60, riseFall: -7 },
    May: { afterOp: 835.60, riseFall: 1 },
    June: { afterOp: 706.80, riseFall: -15 },
    July: { afterOp: 701.20, riseFall: -1 },
    August: { afterOp: 660.80, riseFall: -6 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 8,
    serviceName: 'Tafawwaq',
    January: { afterOp: 9663.60, riseFall: 0 },
    February: { afterOp: 7508.80, riseFall: -22 },
    March: { afterOp: 7005.20, riseFall: -7 },
    April: { afterOp: 6021.20, riseFall: -14 },
    May: { afterOp: 5377.20, riseFall: -11 },
    June: { afterOp: 4454.40, riseFall: -17 },
    July: { afterOp: 4106.80, riseFall: -8 },
    August: { afterOp: 3688.80, riseFall: -10 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 9,
    serviceName: 'Paw Love',
    January: { afterOp: 516.40, riseFall: 0 },
    February: { afterOp: 449.60, riseFall: -13 },
    March: { afterOp: 406.80, riseFall: -10 },
    April: { afterOp: 404.00, riseFall: -1 },
    May: { afterOp: 376.00, riseFall: -7 },
    June: { afterOp: 335.20, riseFall: -11 },
    July: { afterOp: 300.40, riseFall: -10 },
    August: { afterOp: 291.20, riseFall: -3 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 10,
    serviceName: 'Riddles',
    January: { afterOp: 190.40, riseFall: 0 },
    February: { afterOp: 181.60, riseFall: -5 },
    March: { afterOp: 185.60, riseFall: 2 },
    April: { afterOp: 173.20, riseFall: -7 },
    May: { afterOp: 156.80, riseFall: -9 },
    June: { afterOp: 124.80, riseFall: -20 },
    July: { afterOp: 142.80, riseFall: 14 },
    August: { afterOp: 146.40, riseFall: 3 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 11,
    serviceName: 'Top3',
    January: { afterOp: 1265.20, riseFall: 0 },
    February: { afterOp: 997.20, riseFall: -21 },
    March: { afterOp: 1058.80, riseFall: 6 },
    April: { afterOp: 878.80, riseFall: -17 },
    May: { afterOp: 854.00, riseFall: -3 },
    June: { afterOp: 654.80, riseFall: -23 },
    July: { afterOp: 642.40, riseFall: -2 },
    August: { afterOp: 629.60, riseFall: -2 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 12,
    serviceName: 'So7ati',
    January: { afterOp: 0.53, riseFall: 0 },
    February: { afterOp: 0.26, riseFall: -50 },
    March: { afterOp: 0.26, riseFall: 0 },
    April: { afterOp: 0.53, riseFall: 100 },
    May: { afterOp: 0.53, riseFall: 0 },
    June: { afterOp: 0.00, riseFall: -100 },
    July: { afterOp: 0.26, riseFall: '' },
    August: { afterOp: 0.53, riseFall: '' },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 13,
    serviceName: 'Alam Al Muslim',
    January: { afterOp: 702.24, riseFall: 0 },
    February: { afterOp: 568.39, riseFall: -19 },
    March: { afterOp: 587.14, riseFall: 3 },
    April: { afterOp: 487.61, riseFall: -17 },
    May: { afterOp: 456.46, riseFall: -6 },
    June: { afterOp: 426.36, riseFall: -7 },
    July: { afterOp: 414.74, riseFall: -3 },
    August: { afterOp: 399.43, riseFall: -4 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 14,
    serviceName: 'Kids Tips',
    January: { afterOp: 1.85, riseFall: 0 },
    February: { afterOp: 0.26, riseFall: -86 },
    March: { afterOp: 0.79, riseFall: 200 },
    April: { afterOp: 0.26, riseFall: -67 },
    May: { afterOp: 0.26, riseFall: 0 },
    June: { afterOp: 0.53, riseFall: 100 },
    July: { afterOp: 0.26, riseFall: -50 },
    August: { afterOp: 0.26, riseFall: 0 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 15,
    serviceName: 'Diet and Nutrition 2',
    January: { afterOp: 324.19, riseFall: 0 },
    February: { afterOp: 298.32, riseFall: -8 },
    March: { afterOp: 339.24, riseFall: 14 },
    April: { afterOp: 322.34, riseFall: -5 },
    May: { afterOp: 336.34, riseFall: 4 },
    June: { afterOp: 304.92, riseFall: -9 },
    July: { afterOp: 309.14, riseFall: 1 },
    August: { afterOp: 305.18, riseFall: -1 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 16,
    serviceName: 'Ancient Poems',
    January: { afterOp: 235.04, riseFall: 0 },
    February: { afterOp: 218.56, riseFall: -7 },
    March: { afterOp: 233.76, riseFall: 7 },
    April: { afterOp: 225.44, riseFall: -4 },
    May: { afterOp: 214.72, riseFall: -5 },
    June: { afterOp: 211.36, riseFall: -2 },
    July: { afterOp: 198.08, riseFall: -6 },
    August: { afterOp: 195.68, riseFall: -1 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 17,
    serviceName: 'Alphaworld',
    January: { afterOp: 0, riseFall: 0 },
    February: { afterOp: 0, riseFall: 0 },
    March: { afterOp: 6320.00, riseFall: 0 },
    April: { afterOp: 10997.60, riseFall: 74 },
    May: { afterOp: 14457.60, riseFall: 31 },
    June: { afterOp: 20578.40, riseFall: 42 },
    July: { afterOp: 23560.00, riseFall: 14 },
    August: { afterOp: 21340.80, riseFall: -9 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 18,
    serviceName: 'MobFun',
    January: { afterOp: 860.90, riseFall: 0 },
    February: { afterOp: 780.12, riseFall: -9 },
    March: { afterOp: 779.86, riseFall: 0 },
    April: { afterOp: 698.81, riseFall: -10 },
    May: { afterOp: 756.36, riseFall: 8 },
    June: { afterOp: 633.07, riseFall: -16 },
    July: { afterOp: 665.02, riseFall: 5 },
    August: { afterOp: 632.02, riseFall: -5 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 19,
    serviceName: 'Best Food Recipes',
    January: { afterOp: 38.81, riseFall: 0 },
    February: { afterOp: 27.19, riseFall: -30 },
    March: { afterOp: 33.79, riseFall: 24 },
    April: { afterOp: 24.02, riseFall: -29 },
    May: { afterOp: 23.76, riseFall: -1 },
    June: { afterOp: 18.22, riseFall: -23 },
    July: { afterOp: 21.91, riseFall: 20 },
    August: { afterOp: 23.23, riseFall: 6 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 20,
    serviceName: 'Uturn App',
    January: { afterOp: 10.50, riseFall: 0 },
    February: { afterOp: 12.00, riseFall: 14 },
    March: { afterOp: 15.00, riseFall: 25 },
    April: { afterOp: 15.00, riseFall: 0 },
    May: { afterOp: 13.50, riseFall: -10 },
    June: { afterOp: 15.00, riseFall: 11 },
    July: { afterOp: 10.50, riseFall: -30 },
    August: { afterOp: 10.50, riseFall: 0 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 21,
    serviceName: 'Ehorizon Boku',
    January: { afterOp: 12.00, riseFall: 0 },
    February: { afterOp: 4.00, riseFall: -67 },
    March: { afterOp: 12.00, riseFall: 200 },
    April: { afterOp: '', riseFall: '' },
    May: { afterOp: 0.00, riseFall: 0 },
    June: { afterOp: 4.00, riseFall: 0 },
    July: { afterOp: '', riseFall: '' },
    August: { afterOp: '', riseFall: '' },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 22,
    serviceName: 'GameLords',
    January: { afterOp: 1483.42, riseFall: 0 },
    February: { afterOp: 1340.59, riseFall: -10 },
    March: { afterOp: 1400.52, riseFall: 4 },
    April: { afterOp: 1239.74, riseFall: -11 },
    May: { afterOp: 1292.28, riseFall: 4 },
    June: { afterOp: 1202.78, riseFall: -7 },
    July: { afterOp: 1175.86, riseFall: -2 },
    August: { afterOp: 1097.98, riseFall: -7 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  },
  {
    id: 23,
    serviceName: 'YourTime',
    January: { afterOp: '', riseFall: '' },
    February: { afterOp: '', riseFall: '' },
    March: { afterOp: '', riseFall: '' },
    April: { afterOp: '', riseFall: '' },
    May: { afterOp: 20.00, riseFall: 0 },
    June: { afterOp: 24.00, riseFall: 20 },
    July: { afterOp: 29.60, riseFall: 23 },
    August: { afterOp: 12.80, riseFall: -57 },
    September: { afterOp: '', riseFall: '' },
    October: { afterOp: '', riseFall: '' },
    November: { afterOp: '', riseFall: '' },
    December: { afterOp: '', riseFall: '' }
  }
];

const dataStc = [
  {
    "id": 1,
    "serviceName": "Sports Zone",
    "January": { "afterOp": 98944.50, "riseFall": "" },
    "February": { "afterOp": 92610.00, "riseFall": "-6.40%" },
    "March": { "afterOp": 85979.50, "riseFall": "-7.16%" },
    "April": { "afterOp": 84857.00, "riseFall": "-1.31%" },
    "May": { "afterOp": 81184.50, "riseFall": "-4.33%" },
    "June": { "afterOp": 78661.00, "riseFall": "-3.11%" },
    "July": { "afterOp": 74016.50, "riseFall": "-5.90%" },
    "August": { "afterOp": 73502.50, "riseFall": "-0.69%" },
    "September": { "afterOp": 70379.50, "riseFall": "-4.25%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 2,
    "serviceName": "FunZone",
    "January": { "afterOp": 53088.50, "riseFall": "" },
    "February": { "afterOp": 49617.00, "riseFall": "-6.54%" },
    "March": { "afterOp": 46335.50, "riseFall": "-6.61%" },
    "April": { "afterOp": 45545.00, "riseFall": "-1.71%" },
    "May": { "afterOp": 42702.50, "riseFall": "-6.24%" },
    "June": { "afterOp": 41213.50, "riseFall": "-3.49%" },
    "July": { "afterOp": 38712.50, "riseFall": "-6.07%" },
    "August": { "afterOp": 38259.00, "riseFall": "-1.17%" },
    "September": { "afterOp": 36573.00, "riseFall": "-4.41%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 3,
    "serviceName": "Hiya 2",
    "January": { "afterOp": 27797.00, "riseFall": "" },
    "February": { "afterOp": 25543.00, "riseFall": "-8.11%" },
    "March": { "afterOp": 23747.50, "riseFall": "-7.03%" },
    "April": { "afterOp": 23009.50, "riseFall": "-3.11%" },
    "May": { "afterOp": 22047.00, "riseFall": "-4.18%" },
    "June": { "afterOp": 21509.00, "riseFall": "-2.44%" },
    "July": { "afterOp": 20411.50, "riseFall": "-5.10%" },
    "August": { "afterOp": 19776.50, "riseFall": "-3.11%" },
    "September": { "afterOp": 18817.00, "riseFall": "-4.85%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 4,
    "serviceName": "Tafawwaq",
    "January": { "afterOp": 40117.50, "riseFall": "" },
    "February": { "afterOp": 32954.00, "riseFall": "-17.86%" },
    "March": { "afterOp": 29223.50, "riseFall": "-11.32%" },
    "April": { "afterOp": 27453.00, "riseFall": "-6.06%" },
    "May": { "afterOp": 24778.50, "riseFall": "-9.74%" },
    "June": { "afterOp": 23266.50, "riseFall": "-6.10%" },
    "July": { "afterOp": 21543.00, "riseFall": "-7.41%" },
    "August": { "afterOp": 21004.00, "riseFall": "-2.50%" },
    "September": { "afterOp": 19525.00, "riseFall": "-7.04%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 5,
    "serviceName": "GameZone 2",
    "January": { "afterOp": 22543.00, "riseFall": "" },
    "February": { "afterOp": 21258.50, "riseFall": "-5.70%" },
    "March": { "afterOp": 20171.50, "riseFall": "-5.11%" },
    "April": { "afterOp": 19744.00, "riseFall": "-2.12%" },
    "May": { "afterOp": 18670.50, "riseFall": "-5.44%" },
    "June": { "afterOp": 17808.50, "riseFall": "-4.62%" },
    "July": { "afterOp": 16985.50, "riseFall": "-4.62%" },
    "August": { "afterOp": 16566.00, "riseFall": "-2.47%" },
    "September": { "afterOp": 16122.50, "riseFall": "-2.68%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 6,
    "serviceName": "YourSpot",
    "January": { "afterOp": 44355.50, "riseFall": "" },
    "February": { "afterOp": 37859.00, "riseFall": "-14.65%" },
    "March": { "afterOp": 34052.00, "riseFall": "-10.06%" },
    "April": { "afterOp": 32023.00, "riseFall": "-5.96%" },
    "May": { "afterOp": 29082.50, "riseFall": "-9.18%" },
    "June": { "afterOp": 26897.50, "riseFall": "-7.51%" },
    "July": { "afterOp": 25115.50, "riseFall": "-6.63%" },
    "August": { "afterOp": 23990.50, "riseFall": "-4.48%" },
    "September": { "afterOp": 22928.00, "riseFall": "-4.43%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 7,
    "serviceName": "Mysterious Tricks",
    "January": { "afterOp": 12246.50, "riseFall": "" },
    "February": { "afterOp": 11299.00, "riseFall": "-7.74%" },
    "March": { "afterOp": 10753.50, "riseFall": "-4.83%" },
    "April": { "afterOp": 10356.00, "riseFall": "-3.70%" },
    "May": { "afterOp": 9610.00, "riseFall": "-7.20%" },
    "June": { "afterOp": 9260.00, "riseFall": "-3.64%" },
    "July": { "afterOp": 8793.00, "riseFall": "-5.04%" },
    "August": { "afterOp": 8705.50, "riseFall": "-1.00%" },
    "September": { "afterOp": 8219.00, "riseFall": "-5.59%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 8,
    "serviceName": "LevelUp",
    "January": { "afterOp": 3789.00, "riseFall": "" },
    "February": { "afterOp": 3575.00, "riseFall": "-5.65%" },
    "March": { "afterOp": 3426.50, "riseFall": "-4.15%" },
    "April": { "afterOp": 3196.50, "riseFall": "-6.71%" },
    "May": { "afterOp": 3044.00, "riseFall": "-4.77%" },
    "June": { "afterOp": 2978.50, "riseFall": "-2.15%" },
    "July": { "afterOp": 2707.00, "riseFall": "-9.12%" },
    "August": { "afterOp": 2729.00, "riseFall": "0.81%" },
    "September": { "afterOp": 2443.00, "riseFall": "-10.48%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 9,
    "serviceName": "Top3",
    "January": { "afterOp": 3880.50, "riseFall": "" },
    "February": { "afterOp": 3491.50, "riseFall": "-10.02%" },
    "March": { "afterOp": 3196.00, "riseFall": "-8.46%" },
    "April": { "afterOp": 3090.50, "riseFall": "-3.30%" },
    "May": { "afterOp": 2774.00, "riseFall": "-10.24%" },
    "June": { "afterOp": 2613.50, "riseFall": "-5.79%" },
    "July": { "afterOp": 2483.50, "riseFall": "-4.97%" },
    "August": { "afterOp": 2472.50, "riseFall": "-0.44%" },
    "September": { "afterOp": 2249.00, "riseFall": "-9.04%" },
    "October": { "afterOp": '', "riseFall": "" },
    "November": { "afterOp": '', "riseFall": "" },
    "December": { "afterOp": '', "riseFall": "" }
  },
  {
    "id": 10,
    "serviceName": "Hiya",
    "January": { "afterOp": 4934.40, "riseFall": "" },
    "February": { "afterOp": 4622.25, "riseFall": "-6.33%" },
    "March": { "afterOp": 4222.13, "riseFall": "-8.66%" },
    "April": { "afterOp": 4254.08, "riseFall": "0.76%" },
    "May": { "afterOp": 4003.60, "riseFall": "-5.89%" },
    "June": { "afterOp": 4010.68, "riseFall": "0.18%" },
    "July": { "afterOp": 3587.73, "riseFall": "-10.55%" },
    "August": { "afterOp": 3572.70, "riseFall": "-0.42%" },
    "September": { "afterOp": 3393.53, "riseFall": "-5.01%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 11,
    "serviceName": "Paw Love",
    "January": { "afterOp": 1156.50, "riseFall": "" },
    "February": { "afterOp": 1036.50, "riseFall": "-10.38%" },
    "March": { "afterOp": 1070.50, "riseFall": "3.28%" },
    "April": { "afterOp": 1028.00, "riseFall": "-3.97%" },
    "May": { "afterOp": 986.00, "riseFall": "-4.09%" },
    "June": { "afterOp": 925.50, "riseFall": "-6.14%" },
    "July": { "afterOp": 894.00, "riseFall": "-3.40%" },
    "August": { "afterOp": 898.00, "riseFall": "0.45%" },
    "September": { "afterOp": 888.50, "riseFall": "-1.06%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 12,
    "serviceName": "Riddles",
    "January": { "afterOp": 294.00, "riseFall": "" },
    "February": { "afterOp": 307.50, "riseFall": "4.59%" },
    "March": { "afterOp": 283.00, "riseFall": "-7.97%" },
    "April": { "afterOp": 209.50, "riseFall": "-25.97%" },
    "May": { "afterOp": 215.50, "riseFall": "2.86%" },
    "June": { "afterOp": 196.50, "riseFall": "-8.82%" },
    "July": { "afterOp": 194.00, "riseFall": "-1.27%" },
    "August": { "afterOp": 170.50, "riseFall": "-12.11%" },
    "September": { "afterOp": 173.50, "riseFall": "1.76%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 13,
    "serviceName": "Kids Tips",
    "January": { "afterOp": 339.50, "riseFall": "" },
    "February": { "afterOp": 344.00, "riseFall": "1.33%" },
    "March": { "afterOp": 274.00, "riseFall": "-20.35%" },
    "April": { "afterOp": 329.00, "riseFall": "20.07%" },
    "May": { "afterOp": 284.00, "riseFall": "-13.68%" },
    "June": { "afterOp": 256.00, "riseFall": "-9.86%" },
    "July": { "afterOp": 251.50, "riseFall": "-1.76%" },
    "August": { "afterOp": 249.50, "riseFall": "-0.80%" },
    "September": { "afterOp": 234.00, "riseFall": "-6.21%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 14,
    "serviceName": "Hadeeth",
    "January": { "afterOp": 40.00, "riseFall": "" },
    "February": { "afterOp": 70.00, "riseFall": "75.00%" },
    "March": { "afterOp": 40.00, "riseFall": "-42.86%" },
    "April": { "afterOp": 60.00, "riseFall": "50.00%" },
    "May": { "afterOp": 50.00, "riseFall": "-16.67%" },
    "June": { "afterOp": 50.00, "riseFall": "0.00%" },
    "July": { "afterOp": 50.00, "riseFall": "0.00%" },
    "August": { "afterOp": 50.00, "riseFall": "0.00%" },
    "September": { "afterOp": 50.00, "riseFall": "0.00%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 15,
    "serviceName": "Health Tips",
    "January": { "afterOp": 30.00, "riseFall": "" },
    "February": { "afterOp": 40.00, "riseFall": "33.33%" },
    "March": { "afterOp": 10.00, "riseFall": "-75.00%" },
    "April": { "afterOp": 50.00, "riseFall": "400.00%" },
    "May": { "afterOp": 30.00, "riseFall": "-40.00%" },
    "June": { "afterOp": 30.00, "riseFall": "0.00%" },
    "July": { "afterOp": 30.00, "riseFall": "0.00%" },
    "August": { "afterOp": 30.00, "riseFall": "0.00%" },
    "September": { "afterOp": 30.00, "riseFall": "0.00%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 16,
    "serviceName": "Diet and Nurtition",
    "January": { "afterOp": 125.00, "riseFall": "" },
    "February": { "afterOp": 117.50, "riseFall": "-6.00%" },
    "March": { "afterOp": 117.50, "riseFall": "0.00%" },
    "April": { "afterOp": 105.00, "riseFall": "-10.64%" },
    "May": { "afterOp": 112.50, "riseFall": "7.14%" },
    "June": { "afterOp": 120.00, "riseFall": "6.67%" },
    "July": { "afterOp": 110.00, "riseFall": "-8.33%" },
    "August": { "afterOp": 120.00, "riseFall": "9.09%" },
    "September": { "afterOp": 105.00, "riseFall": "-12.50%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 17,
    "serviceName": "YUUHEALTH",
    "January": { "afterOp": 1010.00, "riseFall": "" },
    "February": { "afterOp": 940.00, "riseFall": "-6.93%" },
    "March": { "afterOp": 927.50, "riseFall": "-1.33%" },
    "April": { "afterOp": 910.00, "riseFall": "-1.89%" },
    "May": { "afterOp": 875.00, "riseFall": "-3.85%" },
    "June": { "afterOp": 840.00, "riseFall": "-4.00%" },
    "July": { "afterOp": 805.00, "riseFall": "-4.17%" },
    "August": { "afterOp": 760.00, "riseFall": "-5.59%" },
    "September": { "afterOp": 737.50, "riseFall": "-2.96%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 18,
    "serviceName": "YUUMUSLIM",
    "January": { "afterOp": 1465.00, "riseFall": "" },
    "February": { "afterOp": 1440.00, "riseFall": "-1.71%" },
    "March": { "afterOp": 1312.50, "riseFall": "-8.85%" },
    "April": { "afterOp": 1330.00, "riseFall": "1.33%" },
    "May": { "afterOp": 1307.50, "riseFall": "-1.69%" },
    "June": { "afterOp": 1345.00, "riseFall": "2.87%" },
    "July": { "afterOp": 1275.00, "riseFall": "-5.20%" },
    "August": { "afterOp": 1312.50, "riseFall": "2.94%" },
    "September": { "afterOp": 1210.00, "riseFall": "-7.81%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 19,
    "serviceName": "ALAM ALMUSLIM",
    "January": { "afterOp": 4.00, "riseFall": "" },
    "February": { "afterOp": 0.50, "riseFall": "-87.50%" },
    "March": { "afterOp": 1.50, "riseFall": "200.00%" },
    "April": { "afterOp": 2.00, "riseFall": "33.33%" },
    "May": { "afterOp": 0.50, "riseFall": "-75.00%" },
    "June": { "afterOp": 3.50, "riseFall": "600.00%" },
    "July": { "afterOp": 2.00, "riseFall": "-42.86%" },
    "August": { "afterOp": 6.00, "riseFall": "200.00%" },
    "September": { "afterOp": 20.00, "riseFall": "233.33%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 20,
    "serviceName": "YourTime",
    "January": { "afterOp": 99.00, "riseFall": "" },
    "February": { "afterOp": 107.50, "riseFall": "8.59%" },
    "March": { "afterOp": 101.50, "riseFall": "-5.58%" },
    "April": { "afterOp": 80.00, "riseFall": "-21.18%" },
    "May": { "afterOp": 83.00, "riseFall": "3.75%" },
    "June": { "afterOp": 61.00, "riseFall": "-26.51%" },
    "July": { "afterOp": 65.50, "riseFall": "7.38%" },
    "August": { "afterOp": 74.00, "riseFall": "12.98%" },
    "September": { "afterOp": 47.00, "riseFall": "-36.49%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 21,
    "serviceName": "Alpha World 1SR",
    "January": { "afterOp": 0.00, "riseFall": "" },
    "February": { "afterOp": 0.00, "riseFall": "" },
    "March": { "afterOp": 0.00, "riseFall": "" },
    "April": { "afterOp": 0.00, "riseFall": "" },
    "May": { "afterOp": 0.00, "riseFall": "" },
    "June": { "afterOp": 0.00, "riseFall": "" },
    "July": { "afterOp": 0.00, "riseFall": "" },
    "August": { "afterOp": 0.00, "riseFall": "" },
    "September": { "afterOp": 0.00, "riseFall": "" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 22,
    "serviceName": "Alpha World 2SR",
    "January": { "afterOp": 0.00, "riseFall": "" },
    "February": { "afterOp": 2.00, "riseFall": "" },
    "March": { "afterOp": 5531.00, "riseFall": "" },
    "April": { "afterOp": 13382.00, "riseFall": "141.95%" },
    "May": { "afterOp": 19088.00, "riseFall": "42.64%" },
    "June": { "afterOp": 13988.00, "riseFall": "-26.72%" },
    "July": { "afterOp": 11355.00, "riseFall": "-18.82%" },
    "August": { "afterOp": 10321.00, "riseFall": "-9.11%" },
    "September": { "afterOp": 9061.00, "riseFall": "-12.21%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 23,
    "serviceName": "MobFun",
    "January": { "afterOp": 39631.23, "riseFall": "" },
    "February": { "afterOp": 36737.53, "riseFall": "-7.30%" },
    "March": { "afterOp": 34371.20, "riseFall": "-6.44%" },
    "April": { "afterOp": 33622.80, "riseFall": "-2.18%" },
    "May": { "afterOp": 32116.15, "riseFall": "-4.48%" },
    "June": { "afterOp": 31241.30, "riseFall": "-2.72%" },
    "July": { "afterOp": 29494.35, "riseFall": "-5.59%" },
    "August": { "afterOp": 28896.68, "riseFall": "-2.03%" },
    "September": { "afterOp": 27427.50, "riseFall": "-5.08%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 24,
    "serviceName": "GameLords",
    "January": { "afterOp": 1627.50, "riseFall": "" },
    "February": { "afterOp": 1562.50, "riseFall": "-3.99%" },
    "March": { "afterOp": 1475.00, "riseFall": "-5.60%" },
    "April": { "afterOp": 1405.00, "riseFall": "-4.75%" },
    "May": { "afterOp": 1347.50, "riseFall": "-4.09%" },
    "June": { "afterOp": 1347.50, "riseFall": "0.00%" },
    "July": { "afterOp": 1267.50, "riseFall": "-5.94%" },
    "August": { "afterOp": 1277.50, "riseFall": "0.79%" },
    "September": { "afterOp": 1230.00, "riseFall": "-3.72%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 25,
    "serviceName": "Hawaa",
    "January": { "afterOp": 8075.00, "riseFall": "" },
    "February": { "afterOp": 7842.50, "riseFall": "-2.88%" },
    "March": { "afterOp": 7500.00, "riseFall": "-4.37%" },
    "April": { "afterOp": 7355.00, "riseFall": "-1.93%" },
    "May": { "afterOp": 7132.50, "riseFall": "-3.03%" },
    "June": { "afterOp": 7210.00, "riseFall": "1.09%" },
    "July": { "afterOp": 6970.00, "riseFall": "-3.33%" },
    "August": { "afterOp": 7282.50, "riseFall": "4.48%" },
    "September": { "afterOp": 6812.50, "riseFall": "-6.45%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 26,
    "serviceName": "Hawaa General",
    "January": { "afterOp": 6047.50, "riseFall": "" },
    "February": { "afterOp": 6047.50, "riseFall": "0.00%" },
    "March": { "afterOp": 5407.50, "riseFall": "-10.58%" },
    "April": { "afterOp": 5597.50, "riseFall": "3.51%" },
    "May": { "afterOp": 5257.50, "riseFall": "-6.07%" },
    "June": { "afterOp": 5392.50, "riseFall": "2.57%" },
    "July": { "afterOp": 5097.50, "riseFall": "-5.47%" },
    "August": { "afterOp": 5200.00, "riseFall": "2.01%" },
    "September": { "afterOp": 4922.50, "riseFall": "-5.34%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 27,
    "serviceName": "YUUGOSSIP",
    "January": { "afterOp": 512.50, "riseFall": "" },
    "February": { "afterOp": 520.00, "riseFall": "1.46%" },
    "March": { "afterOp": 460.00, "riseFall": "-11.54%" },
    "April": { "afterOp": 520.00, "riseFall": "13.04%" },
    "May": { "afterOp": 482.50, "riseFall": "-7.21%" },
    "June": { "afterOp": 510.00, "riseFall": "5.70%" },
    "July": { "afterOp": 482.50, "riseFall": "-5.39%" },
    "August": { "afterOp": 490.00, "riseFall": "1.55%" },
    "September": { "afterOp": 470.00, "riseFall": "-4.08%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 28,
    "serviceName": "Kido Kingdom",
    "January": { "afterOp": 1540.00, "riseFall": "" },
    "February": { "afterOp": 1422.50, "riseFall": "-7.63%" },
    "March": { "afterOp": 1460.00, "riseFall": "2.64%" },
    "April": { "afterOp": 1397.50, "riseFall": "-4.28%" },
    "May": { "afterOp": 1375.00, "riseFall": "-1.61%" },
    "June": { "afterOp": 1387.50, "riseFall": "0.91%" },
    "July": { "afterOp": 1355.00, "riseFall": "-2.34%" },
    "August": { "afterOp": 1372.50, "riseFall": "1.29%" },
    "September": { "afterOp": 1375.00, "riseFall": "0.18%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 29,
    "serviceName": "MARVEL COOKING",
    "January": { "afterOp": 115.00, "riseFall": "" },
    "February": { "afterOp": 102.50, "riseFall": "-10.87%" },
    "March": { "afterOp": 100.00, "riseFall": "-2.44%" },
    "April": { "afterOp": 105.00, "riseFall": "5.00%" },
    "May": { "afterOp": 112.50, "riseFall": "7.14%" },
    "June": { "afterOp": 105.00, "riseFall": "-6.67%" },
    "July": { "afterOp": 110.00, "riseFall": "4.76%" },
    "August": { "afterOp": 107.50, "riseFall": "-2.27%" },
    "September": { "afterOp": 105.00, "riseFall": "-2.33%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 30,
    "serviceName": "MARVEL CHILD VIDEO",
    "January": { "afterOp": 3622.50, "riseFall": "" },
    "February": { "afterOp": 3572.50, "riseFall": "-1.38%" },
    "March": { "afterOp": 3312.50, "riseFall": "-7.28%" },
    "April": { "afterOp": 3357.50, "riseFall": "1.36%" },
    "May": { "afterOp": 3212.50, "riseFall": "-4.32%" },
    "June": { "afterOp": 3192.50, "riseFall": "-0.62%" },
    "July": { "afterOp": 3090.00, "riseFall": "-3.21%" },
    "August": { "afterOp": 3090.00, "riseFall": "0.00%" },
    "September": { "afterOp": 3027.50, "riseFall": "-2.02%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 31,
    "serviceName": "Play Rabbit",
    "January": { "afterOp": 552.50, "riseFall": "" },
    "February": { "afterOp": 500.00, "riseFall": "-9.50%" },
    "March": { "afterOp": 457.50, "riseFall": "-8.50%" },
    "April": { "afterOp": 460.00, "riseFall": "0.55%" },
    "May": { "afterOp": 405.00, "riseFall": "-11.96%" },
    "June": { "afterOp": 400.00, "riseFall": "-1.23%" },
    "July": { "afterOp": 412.50, "riseFall": "3.13%" },
    "August": { "afterOp": 357.50, "riseFall": "-13.33%" },
    "September": { "afterOp": 372.50, "riseFall": "4.20%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 32,
    "serviceName": "PlayRabbits 2",
    "January": { "afterOp": 0.00, "riseFall": "" },
    "February": { "afterOp": 0.00, "riseFall": "" },
    "March": { "afterOp": 0.00, "riseFall": "" },
    "April": { "afterOp": 0.00, "riseFall": "" },
    "May": { "afterOp": 0.00, "riseFall": "" },
    "June": { "afterOp": 0.00, "riseFall": "" },
    "July": { "afterOp": 0.00, "riseFall": "" },
    "August": { "afterOp": 0.50, "riseFall": "" },
    "September": { "afterOp": 0.00, "riseFall": "-100.00%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 33,
    "serviceName": "HIA",
    "January": { "afterOp": 217.50, "riseFall": "" },
    "February": { "afterOp": 210.00, "riseFall": "-3.45%" },
    "March": { "afterOp": 197.50, "riseFall": "-5.95%" },
    "April": { "afterOp": 215.00, "riseFall": "8.86%" },
    "May": { "afterOp": 210.00, "riseFall": "-2.33%" },
    "June": { "afterOp": 195.00, "riseFall": "-7.14%" },
    "July": { "afterOp": 197.50, "riseFall": "1.28%" },
    "August": { "afterOp": 210.00, "riseFall": "6.33%" },
    "September": { "afterOp": 217.50, "riseFall": "3.57%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 34,
    "serviceName": "Best Food",
    "January": { "afterOp": 196.50, "riseFall": "" },
    "February": { "afterOp": 169.50, "riseFall": "-13.74%" },
    "March": { "afterOp": 137.00, "riseFall": "-19.17%" },
    "April": { "afterOp": 127.50, "riseFall": "-6.93%" },
    "May": { "afterOp": 117.00, "riseFall": "-8.24%" },
    "June": { "afterOp": 95.00, "riseFall": "-18.80%" },
    "July": { "afterOp": 109.50, "riseFall": "15.26%" },
    "August": { "afterOp": 146.50, "riseFall": "33.79%" },
    "September": { "afterOp": 100.50, "riseFall": "-31.40%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  }
];

const dataZain = [
  {
    "id": 1,
    "serviceName": "Tafawwaq",
    "January": { "afterOp": 4016.65, "riseFall": "" },
    "February": { "afterOp": 3309.35, "riseFall": "-18%" },
    "March": { "afterOp": 3276.90, "riseFall": "-1%" },
    "April": { "afterOp": 3312.10, "riseFall": "1%" },
    "May": { "afterOp": 3053.60, "riseFall": "-8%" },
    "June": { "afterOp": 2477.75, "riseFall": "-19%" },
    "July": { "afterOp": 2459.60, "riseFall": "-1%" },
    "August": { "afterOp": 2406.25, "riseFall": "-2%" },
    "September": { "afterOp": 2028.40, "riseFall": "-16%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 2,
    "serviceName": "Paw Love",
    "January": { "afterOp": 394.90, "riseFall": "" },
    "February": { "afterOp": 363.55, "riseFall": "-8%" },
    "March": { "afterOp": 320.65, "riseFall": "-12%" },
    "April": { "afterOp": 323.40, "riseFall": "1%" },
    "May": { "afterOp": 308.00, "riseFall": "-5%" },
    "June": { "afterOp": 288.75, "riseFall": "-6%" },
    "July": { "afterOp": 293.70, "riseFall": "2%" },
    "August": { "afterOp": 310.20, "riseFall": "6%" },
    "September": { "afterOp": 294.80, "riseFall": "-5%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 3,
    "serviceName": "Level Up",
    "January": { "afterOp": 742.50, "riseFall": "" },
    "February": { "afterOp": 662.20, "riseFall": "-11%" },
    "March": { "afterOp": 740.85, "riseFall": "12%" },
    "April": { "afterOp": 595.10, "riseFall": "-20%" },
    "May": { "afterOp": 608.30, "riseFall": "2%" },
    "June": { "afterOp": 544.50, "riseFall": "-10%" },
    "July": { "afterOp": 448.80, "riseFall": "-18%" },
    "August": { "afterOp": 469.70, "riseFall": "5%" },
    "September": { "afterOp": 425.70, "riseFall": "-9%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 4,
    "serviceName": "FunZone",
    "January": { "afterOp": 2636.50, "riseFall": "" },
    "February": { "afterOp": 2210.00, "riseFall": "-16%" },
    "March": { "afterOp": 2348.00, "riseFall": "6%" },
    "April": { "afterOp": 2062.50, "riseFall": "-12%" },
    "May": { "afterOp": 2132.00, "riseFall": "3%" },
    "June": { "afterOp": 1924.50, "riseFall": "-10%" },
    "July": { "afterOp": 1816.50, "riseFall": "-6%" },
    "August": { "afterOp": 1966.50, "riseFall": "8%" },
    "September": { "afterOp": 1806.50, "riseFall": "-8%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 5,
    "serviceName": "Sports Zone",
    "January": { "afterOp": 2924.40, "riseFall": "" },
    "February": { "afterOp": 2361.45, "riseFall": "-17%" },
    "March": { "afterOp": 2612.22, "riseFall": "11%" },
    "April": { "afterOp": 2286.96, "riseFall": "-12%" },
    "May": { "afterOp": 2506.53, "riseFall": "10%" },
    "June": { "afterOp": 2134.08, "riseFall": "-15%" },
    "July": { "afterOp": 2203.11, "riseFall": "3%" },
    "August": { "afterOp": 2098.59, "riseFall": "-5%" },
    "September": { "afterOp": 2003.82, "riseFall": "-5%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 6,
    "serviceName": "Top3",
    "January": { "afterOp": 786.80, "riseFall": "" },
    "February": { "afterOp": 638.04, "riseFall": "-17%" },
    "March": { "afterOp": 588.51, "riseFall": "-8%" },
    "April": { "afterOp": 572.52, "riseFall": "-3%" },
    "May": { "afterOp": 530.79, "riseFall": "-7%" },
    "June": { "afterOp": 472.68, "riseFall": "-11%" },
    "July": { "afterOp": 473.46, "riseFall": "0%" },
    "August": { "afterOp": 474.24, "riseFall": "0%" },
    "September": { "afterOp": 438.36, "riseFall": "-8%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 7,
    "serviceName": "Riddles",
    "January": { "afterOp": 252.00, "riseFall": "" },
    "February": { "afterOp": 208.26, "riseFall": "-15%" },
    "March": { "afterOp": 200.07, "riseFall": "-4%" },
    "April": { "afterOp": 199.29, "riseFall": "0%" },
    "May": { "afterOp": 158.34, "riseFall": "-21%" },
    "June": { "afterOp": 164.97, "riseFall": "4%" },
    "July": { "afterOp": 152.10, "riseFall": "-8%" },
    "August": { "afterOp": 128.70, "riseFall": "-15%" },
    "September": { "afterOp": 134.55, "riseFall": "5%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 8,
    "serviceName": "Mysterious tricks2",
    "January": { "afterOp": 1352.80, "riseFall": "" },
    "February": { "afterOp": 986.31, "riseFall": "-25%" },
    "March": { "afterOp": 1009.32, "riseFall": "2%" },
    "April": { "afterOp": 957.06, "riseFall": "-5%" },
    "May": { "afterOp": 927.81, "riseFall": "-3%" },
    "June": { "afterOp": 783.90, "riseFall": "-16%" },
    "July": { "afterOp": 799.89, "riseFall": "2%" },
    "August": { "afterOp": 822.51, "riseFall": "3%" },
    "September": { "afterOp": 719.94, "riseFall": "-12%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 9,
    "serviceName": "kids tips",
    "January": { "afterOp": 444.00, "riseFall": "" },
    "February": { "afterOp": 433.50, "riseFall": "-2%" },
    "March": { "afterOp": 370.00, "riseFall": "-15%" },
    "April": { "afterOp": 356.00, "riseFall": "-4%" },
    "May": { "afterOp": 356.00, "riseFall": "0%" },
    "June": { "afterOp": 336.00, "riseFall": "-6%" },
    "July": { "afterOp": 305.50, "riseFall": "-9%" },
    "August": { "afterOp": 266.50, "riseFall": "-13%" },
    "September": { "afterOp": 251.50, "riseFall": "-6%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 10,
    "serviceName": "S07ati",
    "January": { "afterOp": 1.56, "riseFall": "" },
    "February": { "afterOp": 4.29, "riseFall": "175%" },
    "March": { "afterOp": 1.95, "riseFall": "-55%" },
    "April": { "afterOp": 5.07, "riseFall": "160%" },
    "May": { "afterOp": 2.73, "riseFall": "-46%" },
    "June": { "afterOp": 1.56, "riseFall": "-43%" },
    "July": { "afterOp": 1.56, "riseFall": "0%" },
    "August": { "afterOp": 0.39, "riseFall": "-75%" },
    "September": { "afterOp": 2.73, "riseFall": "600%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 11,
    "serviceName": "Hiya",
    "January": { "afterOp": 4258.50, "riseFall": "" },
    "February": { "afterOp": 3618.50, "riseFall": "-15%" },
    "March": { "afterOp": 3613.00, "riseFall": "0%" },
    "April": { "afterOp": 3040.00, "riseFall": "-16%" },
    "May": { "afterOp": 3016.00, "riseFall": "-1%" },
    "June": { "afterOp": 2727.00, "riseFall": "-10%" },
    "July": { "afterOp": 2586.50, "riseFall": "-5%" },
    "August": { "afterOp": 2529.00, "riseFall": "-2%" },
    "September": { "afterOp": 2283.00, "riseFall": "-10%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 12,
    "serviceName": "GameZone",
    "January": { "afterOp": 2281.95, "riseFall": "" },
    "February": { "afterOp": 1966.25, "riseFall": "-14%" },
    "March": { "afterOp": 2053.70, "riseFall": "4%" },
    "April": { "afterOp": 1937.10, "riseFall": "-6%" },
    "May": { "afterOp": 1833.15, "riseFall": "-5%" },
    "June": { "afterOp": 1782.00, "riseFall": "-3%" },
    "July": { "afterOp": 1726.45, "riseFall": "-3%" },
    "August": { "afterOp": 1713.25, "riseFall": "-1%" },
    "September": { "afterOp": 1582.35, "riseFall": "-8%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 13,
    "serviceName": "Alam Al Muslim",
    "January": { "afterOp": 98.28, "riseFall": "" },
    "February": { "afterOp": 69.81, "riseFall": "-29%" },
    "March": { "afterOp": 83.85, "riseFall": "20%" },
    "April": { "afterOp": 72.93, "riseFall": "-13%" },
    "May": { "afterOp": 78.39, "riseFall": "7%" },
    "June": { "afterOp": 52.26, "riseFall": "-33%" },
    "July": { "afterOp": 59.67, "riseFall": "14%" },
    "August": { "afterOp": 46.80, "riseFall": "-22%" },
    "September": { "afterOp": 22.62, "riseFall": "-52%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 14,
    "serviceName": "Your Time",
    "January": { "afterOp": 0.00, "riseFall": "" },
    "February": { "afterOp": 0.00, "riseFall": "" },
    "March": { "afterOp": 0.00, "riseFall": "" },
    "April": { "afterOp": 0.00, "riseFall": "" },
    "May": { "afterOp": 0.00, "riseFall": "" },
    "June": { "afterOp": 0.00, "riseFall": "" },
    "July": { "afterOp": 10.92, "riseFall": "87%" },
    "August": { "afterOp": 0.00, "riseFall": "-100%" },
    "September": { "afterOp": 0.00, "riseFall": "" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 15,
    "serviceName": "Alpha World",
    "January": { "afterOp": 2.34, "riseFall": "" },
    "February": { "afterOp": 3.12, "riseFall": "33%" },
    "March": { "afterOp": 3118.44, "riseFall": "99850%" },
    "April": { "afterOp": 4716.66, "riseFall": "51%" },
    "May": { "afterOp": 4563.00, "riseFall": "-3%" },
    "June": { "afterOp": 5814.90, "riseFall": "27%" },
    "July": { "afterOp": 6516.12, "riseFall": "12%" },
    "August": { "afterOp": 7179.90, "riseFall": "10%" },
    "September": { "afterOp": 6655.74, "riseFall": "-7%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 16,
    "serviceName": "Spuul Daily",
    "January": { "afterOp": 22.14, "riseFall": "" },
    "February": { "afterOp": 19.60, "riseFall": "-11%" },
    "March": { "afterOp": 22.51, "riseFall": "15%" },
    "April": { "afterOp": 21.78, "riseFall": "-3%" },
    "May": { "afterOp": 22.50, "riseFall": "3%" },
    "June": { "afterOp": 21.78, "riseFall": "-3%" },
    "July": { "afterOp": 22.14, "riseFall": "2%" },
    "August": { "afterOp": 22.51, "riseFall": "2%" },
    "September": { "afterOp": 21.78, "riseFall": "-3%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 17,
    "serviceName": "Jahiz Basic",
    "January": { "afterOp": 380.66, "riseFall": "" },
    "February": { "afterOp": 341.96, "riseFall": "-10%" },
    "March": { "afterOp": 346.45, "riseFall": "1%" },
    "April": { "afterOp": 338.78, "riseFall": "-2%" },
    "May": { "afterOp": 353.41, "riseFall": "4%" },
    "June": { "afterOp": 325.30, "riseFall": "-8%" },
    "July": { "afterOp": 347.91, "riseFall": "7%" },
    "August": { "afterOp": 348.09, "riseFall": "0%" },
    "September": { "afterOp": 339.25, "riseFall": "-3%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 18,
    "serviceName": "Jahiz Uturn",
    "January": { "afterOp": 108.21, "riseFall": "" },
    "February": { "afterOp": 96.59, "riseFall": "-11%" },
    "March": { "afterOp": 107.76, "riseFall": "12%" },
    "April": { "afterOp": 108.93, "riseFall": "1%" },
    "May": { "afterOp": 127.04, "riseFall": "17%" },
    "June": { "afterOp": 114.08, "riseFall": "-10%" },
    "July": { "afterOp": 111.23, "riseFall": "-2%" },
    "August": { "afterOp": 120.74, "riseFall": "9%" },
    "September": { "afterOp": 93.16, "riseFall": "-23%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 19,
    "serviceName": "VBOX",
    "January": { "afterOp": 81.68, "riseFall": "" },
    "February": { "afterOp": 363.60, "riseFall": "345%" },
    "March": { "afterOp": 57.02, "riseFall": "-84%" },
    "April": { "afterOp": 50.95, "riseFall": "-11%" },
    "May": { "afterOp": 80.58, "riseFall": "58%" },
    "June": { "afterOp": 73.68, "riseFall": "-9%" },
    "July": { "afterOp": 56.62, "riseFall": "-23%" },
    "August": { "afterOp": 54.65, "riseFall": "-3%" },
    "September": { "afterOp": 52.54, "riseFall": "-4%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 20,
    "serviceName": "Spuul Ad",
    "January": { "afterOp": 77.99, "riseFall": "" },
    "February": { "afterOp": 62.03, "riseFall": "-20%" },
    "March": { "afterOp": 58.69, "riseFall": "-5%" },
    "April": { "afterOp": 60.75, "riseFall": "4%" },
    "May": { "afterOp": 59.66, "riseFall": "-2%" },
    "June": { "afterOp": 43.03, "riseFall": "-28%" },
    "July": { "afterOp": 43.03, "riseFall": "0%" },
    "August": { "afterOp": 39.12, "riseFall": "-9%" },
    "September": { "afterOp": 32.69, "riseFall": "-16%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 21,
    "serviceName": "VBox Digital",
    "January": { "afterOp": 4892.80, "riseFall": "" },
    "February": { "afterOp": 4328.50, "riseFall": "-12%" },
    "March": { "afterOp": 4286.15, "riseFall": "-1%" },
    "April": { "afterOp": 4045.25, "riseFall": "-6%" },
    "May": { "afterOp": 4159.10, "riseFall": "3%" },
    "June": { "afterOp": 3826.90, "riseFall": "-8%" },
    "July": { "afterOp": 3682.25, "riseFall": "-4%" },
    "August": { "afterOp": 3868.15, "riseFall": "5%" },
    "September": { "afterOp": 3520.55, "riseFall": "-9%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 22,
    "serviceName": "VBox Sub",
    "January": { "afterOp": 433.20, "riseFall": "" },
    "February": { "afterOp": 363.60, "riseFall": "-16%" },
    "March": { "afterOp": 394.40, "riseFall": "8%" },
    "April": { "afterOp": 346.80, "riseFall": "-12%" },
    "May": { "afterOp": 371.60, "riseFall": "7%" },
    "June": { "afterOp": 315.60, "riseFall": "-15%" },
    "July": { "afterOp": 318.80, "riseFall": "1%" },
    "August": { "afterOp": 295.60, "riseFall": "-7%" },
    "September": { "afterOp": 314.80, "riseFall": "6%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 23,
    "serviceName": "Mobile Maestro",
    "January": { "afterOp": 121.55, "riseFall": "" },
    "February": { "afterOp": 117.70, "riseFall": "-3%" },
    "March": { "afterOp": 114.95, "riseFall": "-2%" },
    "April": { "afterOp": 114.40, "riseFall": "0%" },
    "May": { "afterOp": 112.20, "riseFall": "-2%" },
    "June": { "afterOp": 112.20, "riseFall": "0%" },
    "July": { "afterOp": 100.65, "riseFall": "-10%" },
    "August": { "afterOp": 56.10, "riseFall": "-44%" },
    "September": { "afterOp": 45.65, "riseFall": "-19%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 24,
    "serviceName": "MobFun",
    "January": { "afterOp": 1523.60, "riseFall": "" },
    "February": { "afterOp": 1363.20, "riseFall": "-11%" },
    "March": { "afterOp": 1464.00, "riseFall": "7%" },
    "April": { "afterOp": 1305.20, "riseFall": "-11%" },
    "May": { "afterOp": 1334.80, "riseFall": "2%" },
    "June": { "afterOp": 1205.60, "riseFall": "-10%" },
    "July": { "afterOp": 1134.40, "riseFall": "-6%" },
    "August": { "afterOp": 1140.80, "riseFall": "1%" },
    "September": { "afterOp": 1120.00, "riseFall": "-2%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 25,
    "serviceName": "kido kingdom",
    "January": { "afterOp": 28.80, "riseFall": "" },
    "February": { "afterOp": 22.80, "riseFall": "-21%" },
    "March": { "afterOp": 28.40, "riseFall": "25%" },
    "April": { "afterOp": 26.00, "riseFall": "-8%" },
    "May": { "afterOp": 25.20, "riseFall": "-3%" },
    "June": { "afterOp": 29.20, "riseFall": "16%" },
    "July": { "afterOp": 18.80, "riseFall": "-36%" },
    "August": { "afterOp": 25.20, "riseFall": "34%" },
    "September": { "afterOp": 20.00, "riseFall": "-21%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 26,
    "serviceName": "Play Rabbits2",
    "January": { "afterOp": 71.50, "riseFall": "" },
    "February": { "afterOp": 47.00, "riseFall": "-34%" },
    "March": { "afterOp": 37.00, "riseFall": "-21%" },
    "April": { "afterOp": 48.50, "riseFall": "31%" },
    "May": { "afterOp": 67.50, "riseFall": "39%" },
    "June": { "afterOp": 34.00, "riseFall": "-50%" },
    "July": { "afterOp": 51.00, "riseFall": "50%" },
    "August": { "afterOp": 59.00, "riseFall": "16%" },
    "September": { "afterOp": 36.50, "riseFall": "-38%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 27,
    "serviceName": "VBOX Weekly",
    "January": { "afterOp": 1268.14, "riseFall": "" },
    "February": { "afterOp": 843.38, "riseFall": "-33%" },
    "March": { "afterOp": 841.18, "riseFall": "0%" },
    "April": { "afterOp": 685.61, "riseFall": "-18%" },
    "May": { "afterOp": 611.85, "riseFall": "-11%" },
    "June": { "afterOp": 564.78, "riseFall": "-8%" },
    "July": { "afterOp": 535.60, "riseFall": "-5%" },
    "August": { "afterOp": 509.97, "riseFall": "-5%" },
    "September": { "afterOp": 347.50, "riseFall": "-32%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  },
  {
    "id": 28,
    "serviceName": "Game Lords",
    "January": { "afterOp": 280.50, "riseFall": "" },
    "February": { "afterOp": 254.65, "riseFall": "-9%" },
    "March": { "afterOp": 265.65, "riseFall": "4%" },
    "April": { "afterOp": 252.45, "riseFall": "-5%" },
    "May": { "afterOp": 305.25, "riseFall": "21%" },
    "June": { "afterOp": 272.80, "riseFall": "-11%" },
    "July": { "afterOp": 243.65, "riseFall": "-11%" },
    "August": { "afterOp": 260.70, "riseFall": "7%" },
    "September": { "afterOp": 239.80, "riseFall": "-8%" },
    "October": { "afterOp": null, "riseFall": "" },
    "November": { "afterOp": null, "riseFall": "" },
    "December": { "afterOp": null, "riseFall": "" }
  }
]


const months = [
  { en: 'January', ar: 'يناير', value: 1 },
  { en: 'February', ar: 'فبراير', value: 2 },
  { en: 'March', ar: 'مارس', value: 3 },
  { en: 'April', ar: 'أبريل', value: 4 },
  { en: 'May', ar: 'مايو', value: 5 },
  { en: 'June', ar: 'يونيو', value: 6 },
  { en: 'July', ar: 'يوليو', value: 7 },
  { en: 'August', ar: 'أغسطس', value: 8 },
  { en: 'September', ar: 'سبتمبر', value: 9 },
  { en: 'October', ar: 'أكتوبر', value: 10 },
  { en: 'November', ar: 'نوفمبر', value: 11 },
  { en: 'December', ar: 'ديسمبر', value: 12 }
];

function RevenueAnalysis() {

  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [currOperator, setCurrOperator] = useState('MOBILY');
  const [analyticsData, setAnalyticsData] = useState([]);

  const totals = analyticsData.length > 0 && months.reduce(
    (acc, month) => {
      const totalAfterOp = analyticsData.reduce((sum, row) => sum + parseFloat(row[month.value]?.afterOp || 0), 0);
      const totalRiseFall = analyticsData.reduce((sum, row) => sum + parseFloat(row[month.value]?.riseFall || 0), 0);
      return {
        ...acc,
        [month.en]: {
          totalAfterOp,
          totalRiseFall,
        },
      };
    },
    {}
  );

  const fetchFinancialDetails = async () =>{
    setLoading(true);
    const submitData = {
      opId: currOperator === "MOBILY" ? 2 : currOperator === "ZAIN" ? 3 : 4,
      year: 2024
    }
    await getFinancialDetails(submitData)
      .then((res)=>{
        console.log(res);
        setAnalyticsData(res);
      })
      .catch((err)=>{console.log(err)})
      .finally(()=>{
        setLoading(false);
      })
  }

  useEffect(() => {
    fetchFinancialDetails();
  }, [currOperator]);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("GLOBAL.DASHBOARD")}</h4>
      </div>
      <div className="flex items-center gap-4">
        <button
          onClick={()=> setCurrOperator("MOBILY") }
          className={cx("border rounded-lg px-8 py-2 font-semibold", {'bg-azure text-white': currOperator === 'MOBILY'})}
        >
          <span>MOBILY</span>
        </button>
        <button
          onClick={()=> setCurrOperator("STC") }
          className={cx("border rounded-lg px-8 py-2 font-semibold", {'bg-azure text-white': currOperator === 'STC'})}
        >
          <span>STC</span>
        </button>
        <button
          onClick={()=> setCurrOperator("ZAIN") }
          className={cx("border rounded-lg px-8 py-2 font-semibold", {'bg-azure text-white': currOperator === 'ZAIN'})}
        >
          <span>ZAIN</span>
        </button>
      </div>
      {
        !loading ?
          <div className='bg-white p-3 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
            <table className="w-full border-collapse border border-gray-300 table-fixed">
              <thead>
              <tr>
                <th className="border border-gray-300 p-2 text-left w-[200px] sticky left-0 bg-white"></th>
                {months.map((month, index) => (
                  <th key={index} colSpan={2}
                      className="border text-gray-500 border-gray-300 p-2 text-center text-sm w-[200px]">
                    {month[i18n.language]}
                  </th>
                ))}
              </tr>

              <tr className="text-xs">
                <th className="border border-gray-300 p-2 w-[200px] sticky left-0 bg-white text-gray-500 text-center">
                  Service Name
                </th>
                {months.map((month, index) => (
                  <>
                    <th key={`${month.en}-afterOp`}
                        className="border text-gray-500 border-gray-300 p-2 text-center w-[200px]">
                      After OP Share
                    </th>
                    <th key={`${month.en}-riseFall`}
                        className="border text-gray-500 border-gray-300 p-2 text-center w-[200px]">
                      Rise and Fall Rate
                    </th>
                  </>
                ))}
              </tr>
              </thead>
              {analyticsData.map((row) => (
                <tr key={row.id}>
                  <td className="border border-gray-300 p-2 min-w-[200px] font-semibold sticky left-0 bg-white">
                    {row.serviceName}
                  </td>

                  {months.map((month, index) => {
                    const currentValue = row[month.value]?.afterOp || 0; // Current month's value
                    const previousValue =
                      index > 0 ? row[months[index - 1].value]?.afterOp || 0 : null; // Previous month's value
                    const rate =
                      currentValue !== 0 && previousValue !== null && previousValue !== 0
                        ? ((currentValue - previousValue) / previousValue) * 100
                        : null; // Calculate rise/fall rate only if currentValue is not 0

                    return (
                      <React.Fragment key={month.en}>
                        <td
                          key={`${month.en}-afterOp`}
                          className="border font-semibold border-gray-300 p-2 w-[200px]"
                        >
                          {currentValue.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) || "-"}
                        </td>
                        <td
                          key={`${month.en}-riseFall`}
                          className={cx("border border-gray-300 p-2 w-[200px] font-semibold", {
                            "text-red-600": rate < 0,
                            "text-green-600": rate > 0,
                          })}
                        >
                          {rate !== null
                            ? `${rate.toFixed(2)}%`
                            : "-"}
                        </td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
              <tr>
                <td className="border border-gray-300 p-2 font-semibold sticky left-0 bg-white text-right">
                  Total
                </td>
                {months.map((month, index) => (
                  <>
                    <td key={`${month.en}-totalAfterOp`} className="border font-semibold border-gray-300 p-2 w-[200px]">
                      {totals[month.en].totalAfterOp.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                    {
                      (() => {
                        const nextMonthTotal = parseFloat(totals[months[index + 1]?.en]?.totalAfterOp || 0);
                        const previousMonthTotal = parseFloat(totals[months[index - 1]?.en]?.totalAfterOp || 0);
                        const currentMonthTotal = parseFloat(totals[month.en].totalAfterOp || 0);

                        const difference = currentMonthTotal - previousMonthTotal;
                        let percentageChange = ((difference / previousMonthTotal) * 100).toFixed(2);

                        if (isNaN(percentageChange) || previousMonthTotal === 0 || currentMonthTotal === 0) {
                          return (
                            <td
                              key={`${month.en}-totalRiseFall`}
                            >
                            </td>
                          )
                        }

                        const textColorClass = percentageChange < 0 ? 'text-red-600' : 'text-green-600';

                        return (
                          <td
                            key={`${month.en}-totalRiseFall`}
                            className={`border font-semibold border-gray-300 p-2 w-[200px] ${textColorClass}`}
                          >
                            {`${percentageChange} %`}
                          </td>
                        );
                      })()
                    }
                  </>
                ))}
              </tr>
            </table>
          </div>
          :
          <div className="h-[50vh] w-full flex items-center justify-center" >
            <div role="status">
              <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                   viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"/>
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"/>
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
            }
          </div>
        );
      }

      export default RevenueAnalysis;