import React, {useEffect, useState} from 'react';
import {
  FiBarChart,
  FiBarChart2,
  FiBriefcase,
  FiChevronRight, FiEye,
  FiFile,
  FiHome,
  FiPieChart,
  FiUserPlus
} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import Modal from "./Modal";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {getFinancialReports, getFinancialStatistics, getOmanCampaignStatistics} from "../services/customSdpApiService";


const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function OmanTelRevenueModal(){
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get('/data.json')
      .then((response) => {
        setData(response.data.OMANTELRevenue);
      })
      .catch((error) => {
        console.error('Error fetching the JSON file:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const totalRevenue = data.reduce((acc, row) => acc + parseFloat(row.revenue.replace(/,/g, '')), 0);
  const totalShare = data.reduce((acc, row) => acc + parseFloat(row.share.replace(/,/g, '')), 0);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">{row.date}</td>
            <td className="border border-gray-300 px-4 py-2">{row.revenue}</td>
            <td className="border border-gray-300 px-4 py-2">{row.share}</td>
          </tr>
        ))}
        <tr className="font-bold bg-gray-200">
          <td className="py-2 px-4 border-t border-gray-300">Total</td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalRevenue.toLocaleString()}
          </td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalShare.toLocaleString()}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

function RevenueModal({opId}){

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalShare, setTotalShare] = useState(0);

  const fetchRevenue = async ()=>{
    const submitData = {
      opId: opId,
      year: 2024
    }
    await getFinancialStatistics(submitData)
      .then((res)=>{
        setData(res.revenueByMonth?.sort((a,b)=> a.month - b.month ))
        setTotalRevenue(res.totalRevenue)
        setTotalShare(res.totalSPShare)
      })
      .catch((err)=>console.log(err));
  }

  useEffect(() => {
    fetchRevenue();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }


  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">
              {months[parseInt(row.month) - 1] || ""}
            </td>
            <td className="border border-gray-300 px-4 py-2">
              {row.revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
            <td className="border border-gray-300 px-4 py-2">
              {row.spShare?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
          </tr>
        ))}
        <tr className="font-bold bg-gray-200">
          <td className="py-2 px-4 border-t border-gray-300">Total</td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalRevenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalShare?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

function Dashboard() {

  const { t, i18n } = useTranslation();

  const [zainFinancial, setZainFinancial] = useState({});
  const [stcFinancial, setStcFinancial] = useState({});
  const [mobilyFinancial, setMobilyFinancial] = useState({});
  const [zainFgcFinancial, setZainFgcFinancial] = useState({});
  const [ksaTotal, setKsaTotal] = useState({TotalRevenue: 0, TotalSPShare: 0, lastMonth: 1});

  const [totalsOMAN, setTotalsOMAN] = useState({
    OMANTEL: { revenue: 0, share: 0 },
    OOREDOO: { revenue: 0, share: 0 },
  });
  const [overallTotalOMAN, setOverallTotalOMAN] = useState({ revenue: '0', share: '0' });

  const [isRevenueModalOpen, setIsRevenueModalOpen] = useState(false);
  const [revenueModalData, setRevenueModalData] = useState(null);

  const [isOmanTelModalOpen, setIsOmanTelModalOpen] = useState(false);

  const [campaignStatisticsStc, setCampaignStatisticsStc] = useState({});
  const [campaignStatisticsMobily, setCampaignStatisticsMobily] = useState({});
  const [campaignStatisticsZain, setCampaignStatisticsZain] = useState({});

  const [campaignStatisticsOmanTel, setCampaignStatisticsOmanTel] = useState({});
  const [campaignStatisticsOreddo, setCampaignStatisticsOreddo] = useState({});

  const [lastUpdate, setLastUpdate] = useState({
    STC: "",
    MOBILY: "",
    ZAIN: "",
    ZAIN_FGCRevenue: "",
    OMANTEL: "",
    OOREDOO: ""
  });

  const openRevenueModal = (opId) => {
    setIsRevenueModalOpen(true);
    setRevenueModalData(opId);
  }

  const closeRevenueModal = () => {
    setRevenueModalData(null);
    setIsRevenueModalOpen(false);
  }


  const openOmanTelModal = ()=>{
    setIsOmanTelModalOpen(true);
  }
  const closeOmanTelModal = ()=>{
    setIsOmanTelModalOpen(false);
  }

  const findLastUpdate = (revenueData) => {
    const lastEntry = revenueData.reverse().find(entry => parseFloat(entry.revenue) !== 0);
    return lastEntry ? lastEntry.date : "";
  };

  const fetchLastUpdate = () => {
    axios.get('/data.json')
      .then((response) => {
        const { STCRevenue, MOBILYRevenue, ZAINRevenue, ZAIN_FGCRevenue, OMANTELRevenue, OOREDOORevenue } = response.data;

        setLastUpdate(prevState => ({
          STC: findLastUpdate([...STCRevenue]),
          MOBILY: findLastUpdate([...MOBILYRevenue]),
          ZAIN: findLastUpdate([...ZAINRevenue]),
          ZAIN_FGCRevenue: findLastUpdate([...ZAIN_FGCRevenue]),
          OMANTEL: findLastUpdate([...OMANTELRevenue]),
          OOREDOO: findLastUpdate([...OOREDOORevenue]),
        }));

      })
      .catch((error) => {
        console.error('Error fetching the JSON file:', error);
      });
  }


  const fetchOMANData = ()=>{
    axios.get('/data.json')
        .then((response) => {
          const { OMANTELRevenue, OOREDOORevenue } = response.data;

          const calculateTotal = (dataArray) => {
            let revenueTotal = 0;
            let shareTotal = 0;

            dataArray.forEach(item => {
              revenueTotal += parseFloat(item.revenue.replace(/,/g, ''));
              shareTotal += parseFloat(item.share.replace(/,/g, ''));
            });

            return {
              revenue: revenueTotal || 0,
              share: shareTotal || 0,
            };
          };

          const omanTelTotal = calculateTotal(OMANTELRevenue);
          const ooredooTotal = calculateTotal(OOREDOORevenue);

          setTotalsOMAN({
            OMANTEL: {
              revenue: omanTelTotal.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              share: omanTelTotal.share.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
            OOREDOO: {
              revenue: ooredooTotal.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              share: ooredooTotal.share.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
          });

          const overallRevenueTotal = omanTelTotal.revenue + ooredooTotal.revenue;
          const overallShareTotal = omanTelTotal.share + ooredooTotal.share;

          setOverallTotalOMAN({
            revenue: overallRevenueTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            share: overallShareTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          });
        })
        .catch((error) => {
          console.error('Error fetching the JSON file:', error);
        });
  }

  const fetchKsaCampaignStatisticsStc = async ()=>{
    await getOmanCampaignStatistics(4, 2024)
      .then((res)=>{
        setCampaignStatisticsStc(res[0]);
      })
      .catch((err)=>{console.log(err)});
  }
  const fetchKsaCampaignStatisticsMobily = async ()=>{
    await getOmanCampaignStatistics(2, 2024)
      .then((res)=>{
        setCampaignStatisticsMobily(res[0]);
      })
      .catch((err)=>{console.log(err)});
  }
  const fetchKsaCampaignStatisticsZain = async ()=>{
    await getOmanCampaignStatistics(3, 2024)
      .then((res)=>{
        setCampaignStatisticsZain(res[0]);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchOmanCampaignStatisticsOmanTel = async ()=>{
    await getOmanCampaignStatistics(5, 2024)
      .then((res)=>{
        setCampaignStatisticsOmanTel(res[0]);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchOmanCampaignStatisticsOreddo = async ()=>{
    await getOmanCampaignStatistics(8, 2024)
      .then((res)=>{
        setCampaignStatisticsOreddo(res[0]);
      })
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    fetchOMANData();
    fetchLastUpdate();

    fetchKsaCampaignStatisticsStc();
    fetchKsaCampaignStatisticsMobily();
    fetchKsaCampaignStatisticsZain();

    fetchOmanCampaignStatisticsOmanTel();
    fetchOmanCampaignStatisticsOreddo();
  }, []);


  const fetchFinancialReports = async () => {
    const requests = [
      { opId: 3, setter: setZainFinancial },
      { opId: 4, setter: setStcFinancial },
      { opId: 2, setter: setMobilyFinancial },
      { opId: 9, setter: setZainFgcFinancial },
    ];

    try {
      const results = await Promise.allSettled(
        requests.map(async ({ opId, setter }) => {
          const response = await getFinancialReports({ opId, year: 2024 });
          setter(response); // Set individual financial data
          return response; // Return the response for aggregation
        })
      );

      let totalRevenue = 0;
      let totalSPShare = 0;
      let maxMonth = 0;

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const data = result.value; // Extract the response data
          totalRevenue += data.TotalRevenue || 0;
          totalSPShare += data.TotalSPShare || 0;
          maxMonth = Math.max(maxMonth, data.MaxMonth || 0);
        } else {
          console.error(`Error fetching financial report for opId ${requests[index].opId}:`, result.reason);
        }
      });

      setKsaTotal({
        TotalRevenue: totalRevenue,
        TotalSPShare: totalSPShare,
        lastMonth: maxMonth,
      });
    } catch (error) {
      console.error("Unexpected error fetching financial reports:", error);
    }
  };

  useEffect(()=>{
    fetchFinancialReports();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("GLOBAL.DASHBOARD")}</h4>
      </div>
      <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
          <tr>
            <th colSpan="4"
                className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
              Total Market revenue 2024
            </th>
          </tr>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Country</th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">KSA</td>
            <td className="border border-gray-300 px-4 py-2">
              {ksaTotal?.TotalRevenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
            <td className="border border-gray-300 px-4 py-2">
              {ksaTotal?.TotalSPShare?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </td>
            <td className="border border-gray-300 px-4 py-2">
              {months[parseInt(ksaTotal?.lastMonth) - 1] || ""}
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">OMAN</td>
            <td className="border border-gray-300 px-4 py-2">{overallTotalOMAN.revenue}</td>
            <td className="border border-gray-300 px-4 py-2">{overallTotalOMAN.share}</td>
            <td className="border border-gray-300 px-4 py-2">{lastUpdate.OMANTEL}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
            <tr>
              <th colSpan="5"
                  className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
                Market revenue KSA 2024
              </th>
            </tr>
            <tr>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td
                onClick={() => openRevenueModal(4)}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                STC
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {stcFinancial?.TotalRevenue?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {stcFinancial?.TotalSPShare?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">{months[parseInt(stcFinancial?.MaxMonth) - 1] || ""}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button>
                  <FiEye size={18} className="hover:text-azure" onClick={() => openRevenueModal(4)} />
                </button>
              </td>
            </tr>
            <tr>
              <td
                onClick={() => openRevenueModal(2)}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                MOBILY
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {mobilyFinancial?.TotalRevenue?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {mobilyFinancial?.TotalSPShare?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {months[parseInt(mobilyFinancial?.MaxMonth) - 1] || ""}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button>
                  <FiEye size={18} className="hover:text-azure" onClick={() => openRevenueModal(2)}/>
                </button>
              </td>
            </tr>
            <tr>
              <td
                onClick={() => openRevenueModal(3)}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                ZAIN
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {zainFinancial?.TotalRevenue?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {zainFinancial?.TotalSPShare?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td
                className="border border-gray-300 px-4 py-2">{months[parseInt(zainFinancial?.MaxMonth) - 1] || ""}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button>
                  <FiEye size={18} className="hover:text-azure" onClick={() => openRevenueModal(3)}/>
                </button>
              </td>
            </tr>
            <tr>
              <td
                onClick={() => openRevenueModal(9)}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                ZAIN-FGC
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {zainFgcFinancial?.TotalRevenue?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {zainFgcFinancial?.TotalSPShare?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {months[parseInt(zainFgcFinancial?.MaxMonth) - 1] || ""}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <button>
                  <FiEye size={18} className="hover:text-azure" onClick={() => openRevenueModal(9)}/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
            <tr>
              <th colSpan="4"
                  className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
                Campaign statistics KSA 2024
              </th>
            </tr>
            <tr>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Spent SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Revenue estimated SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Revenue earned SAR</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">
                STC
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsStc.Spent?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsStc.RevenueEstimated?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsStc.ROI?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Mobily</td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsMobily.Spent?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsMobily.RevenueEstimated?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsMobily.ROI?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Zain</td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsZain.Spent?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsZain.RevenueEstimated?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsZain.ROI?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
            <tr>
              <th colSpan="4"
                  className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
                Market revenue OMAN 2024
              </th>
            </tr>
            <tr>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td onClick={() => openOmanTelModal()} className="cursor-pointer border border-gray-300 px-4 py-2">OMAN
                TEL
              </td>
              <td className="border border-gray-300 px-4 py-2">{totalsOMAN.OMANTEL.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{totalsOMAN.OMANTEL.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.OMANTEL}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">OOREDOO</td>
              <td className="border border-gray-300 px-4 py-2">{totalsOMAN.OOREDOO.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{totalsOMAN.OOREDOO.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.OOREDOO}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
            <tr>
              <th colSpan="4"
                  className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
                Campaign statistics OMAN 2024
              </th>
            </tr>
            <tr>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Spent SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Revenue estimated SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Revenue earned SAR</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="cursor-pointer border border-gray-300 px-4 py-2">
                OMAN TEL
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsOmanTel.Spent?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsOmanTel.RevenueEstimated?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsOmanTel.ROI?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">OOREDOO</td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsOreddo.Spent?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsOreddo.RevenueEstimated?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td className="border border-gray-300 px-4 py-2">
                {campaignStatisticsOreddo.ROI?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


      <Modal isOpen={isOmanTelModalOpen} close={closeOmanTelModal} title="OMAN - OMAN TEL revenue">
        <OmanTelRevenueModal/>
      </Modal>


      <Modal isOpen={isRevenueModalOpen} close={closeRevenueModal} title="Revenues">
        <RevenueModal opId={revenueModalData} />
      </Modal>
    </div>
  );
}

export default Dashboard;
