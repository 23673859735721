import React, {useEffect, useState} from 'react';
import {FiCalendar, FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CustomDropdownFilterObject} from "../ressources/CustomComponents";
import {
  getAllPublishers,
  getCampaignsByPublisher,
  getDailyCampaignRevenue,
  getMonthlyCampaignRevenue
} from "../services/campaignServices";
import DatePicker from "react-datepicker";
import moment from "moment";
import cx from "classnames";
import {FaFileExport} from "react-icons/fa";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import {getAllOperators, getAllOperatorsByCampaign} from "../services/technicalService";

function CampaignStatistics() {

  const {t} = useTranslation();

  const [isPublisherOpen, setIsPublisherOpen] = useState(false);
  const [publisherList, setPublisherList] = useState([]);
  const [publisher, setPublisher] = useState({});

  const [isCampaignOpen, setIsCampaignOpen] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [campaign, setCampaign] = useState({});

  const [isOperatorSelectOpen, setIsOperatorSelectOpen] = useState(false);
  const [operator, setOperator] = useState({});
  const [operatorList, setOperatorList] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [dataType, setDataType] = useState("daily");
  const [loading, setLoading] = useState(false);

  const [campaignsData, setCampaignData] = useState([]);

  const selectPublisher = (val) => {
    setPublisher(val);
    setCampaign({});
    setIsPublisherOpen(false);
  }

  const selectCampaign = (val) => {
    setCampaign(val);
    setIsCampaignOpen(false);
  }

  const selectOperator = (operator) => {
    setOperator(operator);
    setIsOperatorSelectOpen(false);
  }

  function exportToExcel(tableData, fileName) {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  const fetchPublishers = async () => {
    await getAllPublishers()
      .then((res)=> setPublisherList(res.results) )
      .catch((err)=>console.log(err));
  }

  const fetchCampaigns = async () => {
    await getCampaignsByPublisher(publisher.publisherId)
      .then((res)=> setCampaignList(res.results) )
      .catch((err)=>console.log(err));
  }

  const totalAmount = (data, rowName) => data.reduce((sum, row) => sum + row[rowName], 0);

  const fetchCampaignsData = async () => {
    setLoading(true);
    if(dataType === "daily"){
      await getDailyCampaignRevenue(campaign.campaignId, operator.operatorId, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
        .then((res)=> {
          const updatedRes = res.results?.map(item => ({
            ...item,
            dailyEarned: parseFloat((item.totalSpRevenue - item.totalCost).toFixed(2)),
          }));
          const dataWithTotal = [
            ...updatedRes,
            {
              id: 'total',
              serviceName: '',
              // operatorName: '',
              newSubscription: parseInt(totalAmount(updatedRes, "newSubscription")),
              churnCount: parseInt(totalAmount(updatedRes, "churnCount")),
              cpa: '',

              totalCost: parseFloat(totalAmount(updatedRes, "totalCost")).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              dailyRevenue: parseFloat(totalAmount(updatedRes, "dailyRevenue")).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              dailyEarned: totalAmount(updatedRes, "dailyEarned").toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              totalSpRevenue: parseFloat(totalAmount(updatedRes, "totalSpRevenue")).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              totalRevenue: parseFloat(totalAmount(updatedRes, "totalRevenue")).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              totalCostUSD: parseFloat(totalAmount(updatedRes, "totalCostUSD")).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              totalRevenueUSD: parseFloat(totalAmount(updatedRes, "totalRevenueUSD")).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              unSubscription: parseInt(totalAmount(updatedRes, "unSubscription")),
            },
          ];
          setCampaignData(dataWithTotal);
        })
        .catch((err)=>console.log(err))
        .finally(()=> setLoading(false) );
    }else{
      await getMonthlyCampaignRevenue(campaign.campaignId, operator.operatorId, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
        .then((res)=> setCampaignData(res.results) )
        .catch((err)=>console.log(err))
        .finally(()=> setLoading(false) );
    }
  }

  const fetchOperators = async () => {
    await getAllOperatorsByCampaign(campaign.campaignId)
      .then((res)=> setOperatorList(res.results) )
      .catch((err)=>console.log(err));
  }

  useEffect(()=>{
    fetchPublishers();
  }, []);

  useEffect(()=>{
    if(Object.keys(publisher).length > 0){
      fetchCampaigns();
    }
  }, [publisher]);

  useEffect(()=>{
    if(Object.keys(campaign).length > 0){
      fetchOperators();
    }
  }, [campaign]);

  useEffect(()=>{
    if(Object.keys(campaign).length > 0 && startDate && endDate){
      fetchCampaignsData();
    }
  }, [campaign, startDate, endDate, dataType, operator]);

  const columns = [
    {
      name: dataType === "daily" ? 'Date' : 'Month',
      selector: row => row.id === "total" ? "Total" : dataType === "daily" ? moment(row.dated).format("DD-MM-YYYY") : row.monthName,
      sortable: true,
    }, {
      name: 'Service',
      selector: row => row.serviceName,
      sortable: true,
    },
    // {
    //   name: 'Operator',
    //   selector: row => row.operatorName,
    //   sortable: true,
    // },
    {
      name: 'New Subscription',
      selector: row => row.newSubscription,
      sortable: true,
      width: '100px'
    }, {
      name: 'Churn Count',
      selector: row => row.churnCount,
      sortable: true,
      width: '100px'
    }, {
      name: 'CPA',
      selector: row => row?.cpa || '',
      sortable: true,
      width: '100px'
    }, {
      name: 'Total Cost (SAR)',
      selector: row => row.totalCost,
      sortable: true,
    }, {
      name: 'Daily Revenue (SAR)',
      selector: row => row.dailyRevenue,
      sortable: true,
    }, {
      name: 'Total Earned (SAR)',
      selector: row => row.dailyEarned,
      sortable: true,
    }, {
      name: 'Total SP Revenue (SAR)',
      selector: row => row.totalSpRevenue,
      sortable: true,
    }, {
      name: 'Total Revenue (SAR)',
      selector: row => row.totalRevenue,
      sortable: true,
    }, {
      name: 'Total Cost (USD)',
      selector: row => row.totalCostUSD,
      sortable: true,
    }, {
      name: 'Total Revenue (USD)',
      selector: row => row.totalRevenueUSD,
      sortable: true,
    }, {
      name: 'Unsubscription',
      selector: row => row.unSubscription,
      sortable: true,
      width: '100px'
    },

  ];

  const conditionalRowStyles = [
    {
      when: row => row.id === 'total',
      style: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
      },
    },
  ];

  const totalNewSubscription = campaignsData.reduce((acc, item) => acc + item.newSubscription, 0);
  const totalUnSubscription = campaignsData.reduce((acc, item) => acc + item.unSubscription, 0);
  const totalChurnCount = campaignsData.reduce((acc, item) => acc + item.churnCount, 0);
  const totalCost = campaignsData.reduce((acc, item) => acc + item.totalCost, 0);
  const totalCostUSD = campaignsData.reduce((acc, item) => acc + item.totalCostUSD, 0);
  const totalRevenue = campaignsData.reduce((acc, item) => acc + item.totalRevenue, 0);
  const totalRevenueUSD = campaignsData.reduce((acc, item) => acc + item.totalRevenueUSD, 0);
  const totalDailyRevenue = campaignsData.reduce((acc, item) => acc + item.dailyRevenue, 0);

  return (
    <div>
      <div>
        <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
          <span className="text-xs font-semibold flex items-center">
            <FiHome size={12}/> <FiChevronRight size={12}/>
          </span>
          <h4 className="text-xs font-semibold text-gray-500">{t("CAMPAIGN_STATISTICS.TITLE")}</h4>
        </div>
        <div className="mb-6">
          {/*<div className="w-1/4 text-start">*/}
          {/*  <h1 className="text-xl font-bold">{t("CAMPAIGN_STATISTICS.TITLE")}</h1>*/}
          {/*</div>*/}
          <div className="grid grid-cols-3 items-center gap-4 w-full mb-4">
            <div className="w-full">
              <CustomDropdownFilterObject
                label="Publishers"
                isOpen={isPublisherOpen}
                setIsOpen={setIsPublisherOpen}
                itemList={publisherList}
                selectedItem={publisher}
                itemKey="publisherName"
                idKey="publisherId"
                selectItem={selectPublisher}
              />
            </div>
            <div className="w-full">
              <CustomDropdownFilterObject
                label="Campaign"
                isOpen={isCampaignOpen}
                setIsOpen={setIsCampaignOpen}
                itemList={campaignList}
                selectedItem={campaign}
                itemKey="description"
                idKey="campaignId"
                selectItem={selectCampaign}
                disabled={Object.keys(publisher).length === 0}
              />
            </div>
            <div className="w-full">
              <CustomDropdownFilterObject
                label="Operators"
                isOpen={isOperatorSelectOpen}
                setIsOpen={setIsOperatorSelectOpen}
                itemList={[{operatorId: 0, operatorName: "See all"}, ...operatorList]}
                selectedItem={operator}
                itemKey="operatorName"
                idKey="operatorId"
                selectItem={selectOperator}
                disabled={Object.keys(campaign).length === 0}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4 w-full" >
            <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white w-full">
              <FiCalendar className="text-gray-400"/>
              <DatePicker
                selected={startDate}
                onChange={(e) => setStartDate(e)}
                type="text"
                dateFormat="dd/MM/yyyy"
                placeholderText='Date'
                isClearable
                disabled={Object.keys(campaign).length === 0}
                className="w-full"
                wrapperClassName="w-full"
              />
            </div>
            <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white w-full">
              <FiCalendar className="text-gray-400"/>
              <DatePicker
                selected={endDate}
                onChange={(e) => setEndDate(e)}
                type="text"
                dateFormat="dd/MM/yyyy"
                placeholderText='Date'
                isClearable
                disabled={Object.keys(campaign).length === 0}
                className="w-full"
                wrapperClassName="w-full"
              />
            </div>
            <div className="flex items-center gap-3 border rounded-lg p-2 bg-white">
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  checked={dataType === "daily"}
                  onChange={(e) => setDataType("daily")}
                />
                <label className="text-sm font-semibold text-gray-600">Daily</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  checked={dataType === "monthly"}
                  onChange={(e) => setDataType("monthly")}
                />
                <label className="text-sm font-semibold text-gray-600">Monthly</label>
              </div>
            </div>
            <button
              onClick={() => exportToExcel(campaignsData, `revenue-from-${moment(startDate).format('MM/DD/YYYY')}-to-${moment(endDate).format('MM/DD/YYYY')}`)}
              disabled={campaignsData.length <= 0}
              className={cx("bg-azure px-4 py-2 flex items-center gap-3 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow", {
                'bg-azure': campaignsData.length > 0,
                'bg-gray-400': campaignsData.length <= 0,
              })}
            >
              <FaFileExport size={18}/>
              <span>Export file</span>
            </button>
          </div>
        </div>
      </div>
      {
        loading
          ?
          <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto'>
            Loading...
          </div>
          :
          <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto relative z-0 min-h-[60vh]'>
            <DataTable
              columns={columns}
              data={campaignsData}
              customStyles={{
                headCells: {
                  style: {
                    backgroundColor: '#f3f4f6',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                  },
                },
                cells: {
                  style: {
                    padding: '6px',
                    textAlign: 'left',
                  },
                },
              }}
              highlightOnHover
              pagination={false}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
      }

    </div>
  );
}

export default CampaignStatistics;

